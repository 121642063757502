import { ENDPOINTS } from "../../../lib/endpoints";

import { useAxiosPrivate } from "../../../hooks/useAxiosPrivate";

const apiPrivate = useAxiosPrivate();

export async function getTransactions() {
  return await apiPrivate.get(`${ENDPOINTS.PAYMENT}/transactions`);
}

export async function generateInvoice(transactionId) {
  try {
    const response = await apiPrivate.get(`${ENDPOINTS.PAYMENT}/invoice/${transactionId}`, {
      responseType: "blob", // Ensure the response is handled as a blob
    });

    // Create a URL for the blob and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `invoice-${transactionId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url); // Clean up the URL object
  } catch (error) {
    console.error("Error generating invoice:", error);
    // Handle errors (e.g., display a message to the user)
  }
}

export async function fetchClientSecret() {
  return await apiPrivate.get(`${ENDPOINTS.PAYMENT}/clientSecret`);
}

export async function updateLastPaymentMethod(paymentMethodId) {
  return await apiPrivate.post(`${ENDPOINTS.PAYMENT}/update-method`, { paymentMethodId });
}
