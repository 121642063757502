import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <ToastContainer
          className="foo"
          style={{
            width: "23rem",
            fontFamily: "sans-serif",
            fontSize: "0.85rem",
            paddingLeft: "2.3rem",
          }}
          newestOnTop
        />
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
