/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Dashboard from "../pages/dashboard/view";
import Tables from "../pages/tables/view";
import Billing from "../pages/billing/view";
import CodebaseMonitoring from "../pages/CodebaseMonitoring/view";
import VisualSnapshot from "../pages/VisualSnapshot/view";
// import RTL from "./pages/rtl";
// import Notifications from "./pages/notifications";
// import Profile from "../pages/profile/view";
import SignIn from "../pages/authentication/view/sign-in";
import SignUp from "../pages/authentication/view/sign-up";
import Logout from "../pages/authentication/view/log-out";
import Cover from "../pages/authentication/view/reset-password";
// import SubscriptionServices from "../pages/payment/view/subscriptionservices";
import PaymentPage from "../pages/payment/view";

// @mui icons
import Icon from "@mui/material/Icon";
import ContactUs from "../pages/landingpages/view/ContactUs";
import AboutUs from "../pages/landingpages/view/AboutUs";

const routes = [
  {
    type: "collapse",
    name: "CodeBase Monitoring",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <CodebaseMonitoring />,
  },
  {
    type: "collapse",
    name: "Visual Snapshot Comparison ",
    key: "visual-snapshot",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/visual-snapshot",
    component: <VisualSnapshot />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "ResetPassword",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/restpasword",
    component: <Cover />,
  },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Subscription",
    key: "Subscription",
    icon: <Icon fontSize="small">price_change_icon</Icon>,
    route: "/payment",
    component: <PaymentPage />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn brandName={"LeapARMM"} />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp brandName={"LeapARMM"} />,
  },
  {
    type: "collapse",
    name: "Contact US",
    key: "notifications",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/landing/contactus",
    component: <ContactUs />,
  },
  {
    type: "collapse",
    name: "About US",
    key: "aboutus",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/landing/aboutus",
    component: <AboutUs />,
  },
  {
    type: "collapse",
    name: "Log out",
    key: "logout",
    route: "#",
    component: <Logout />,
  },
];

export default routes;
