import React, { useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LinkIcon from "@mui/icons-material/Link";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";
import MDButton from "../../../../../components/MDButton";
import { fetchClientSecret } from "../../../store/api"; // Icon for bank account

// Import credit card brand icons (Assuming you have these icons in your assets)
import VisaIcon from "../../../../../assets/images/icons/visa.svg";
import MasterCardIcon from "../../../../../assets/images/icons/mastercard.svg";
import AmexIcon from "../../../../../assets/images/icons/amex.svg";
import DiscoverIcon from "../../../../../assets/images/icons/discover.svg";

// Create a mapping of card brand names to icons
const brandIcons = {
  visa: VisaIcon,
  mastercard: MasterCardIcon,
  amex: AmexIcon,
  discover: DiscoverIcon,
  // Add more brands as needed
};

export default function PaymentMethod({ setClientSecret }) {
  const [loading, setLoading] = useState(false);
  const lastPaymentMethod = useSelector((state) => state.payment.lastPaymentMethod);

  const changePaymentMethod = async () => {
    setLoading(true);
    try {
      const { data } = await fetchClientSecret();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Failed to fetch client secret:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderPaymentDetails = () => {
    if (!lastPaymentMethod) {
      return (
        <MDTypography variant="body2" color="error">
          No payment method available.
        </MDTypography>
      );
    }

    switch (lastPaymentMethod.type) {
      case "us_bank_account": {
        const { us_bank_account } = lastPaymentMethod;
        const { bank_name, last4, account_type } = us_bank_account;
        return (
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <AccountBalanceIcon color="warning" fontSize="large" />
            </Grid>
            <Grid item>
              <MDTypography variant="body1">
                {lastPaymentMethod?.billing_details?.name || "US Bank Account"}
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <MDTypography variant="body2" fontWeight="medium">
                  **** **** **** {last4}
                </MDTypography>
              </MDBox>
              <MDTypography variant="body2" color="secondary">
                {bank_name} ({account_type.charAt(0).toUpperCase() + account_type.slice(1)})
              </MDTypography>
            </Grid>
          </MDBox>
        );
      }

      case "card": {
        const { card } = lastPaymentMethod;
        const { brand, last4: cardLast4, exp_month, exp_year } = card;

        // Get the brand icon based on the card brand
        const BrandIcon = brandIcons[brand.toLowerCase()] || CreditCardIcon;

        return (
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <img
                src={BrandIcon}
                alt={`${brand} logo`}
                style={{ width: "120px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <MDTypography variant="body1">
                {lastPaymentMethod?.billing_details?.name || "Credit Card"}
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <MDTypography variant="body2" fontWeight="medium">
                  {brand.toUpperCase()} **** **** **** {cardLast4}
                </MDTypography>
              </MDBox>
              <MDTypography variant="body2" color="secondary">
                Expires {exp_month}/{exp_year}
              </MDTypography>
            </Grid>
          </MDBox>
        );
      }

      case "link": {
        return (
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <LinkIcon color="primary" fontSize="large" />
            </Grid>
            <Grid item>
              <MDTypography variant="body1">
                {lastPaymentMethod?.billing_details?.name || "Link Payment"}
              </MDTypography>
              <MDTypography variant="body2" color="secondary">
                {lastPaymentMethod.link?.email}
              </MDTypography>
            </Grid>
          </MDBox>
        );
      }

      default:
        return (
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <ErrorIcon color="primary" fontSize="large" />
            </Grid>
            <Grid item>
              <MDTypography variant="body1" color="primary" fontWeight="bold">
                Unsupported Payment Method
              </MDTypography>
              <MDTypography variant="body2">
                This payment method is not supported. Please select a different method.
              </MDTypography>
            </Grid>
          </MDBox>
        );
    }
  };

  return (
    <MDBox mb={2} sx={{ flex: 1 }}>
      <MDBox mb={2}>
        <MDTypography variant="body2">
          <strong>Payment Method</strong>
        </MDTypography>
        <MDTypography variant="caption" color="secondary">
          Change how you pay your plan
        </MDTypography>
      </MDBox>
      <Card sx={{ height: "75%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Grid
            container
            spacing={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>{renderPaymentDetails()}</Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Edit Payment Method" placement="top">
                <MDButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={changePaymentMethod}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} color="info" sx={{ mr: 1 }} />
                  ) : (
                    <Icon sx={{ cursor: "pointer", mr: 1 }} fontSize="small">
                      edit
                    </Icon>
                  )}
                  {loading ? "Processing..." : "Change"}
                </MDButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MDBox>
  );
}
