import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Slide from "@mui/material/Slide";
import { CircularProgress, Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mui/material/Icon";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import brandWhite from "../../../../../assets/images/logos/logowhite.svg";
import brandDark from "../../../../../assets/images/logos/logodark.svg";
import { updateLastPaymentMethod } from "../../../store/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePaymentForm = ({ clientSecret, darkMode, setClientSecret }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !clientSecret) {
      setMessage("Stripe has not yet initialized.");
      setLoading(false);
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setMessage(submitError.message);
      setSuccess(false);
      setLoading(false);
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://localhost:3000/billing",
      },
      clientSecret,
    });

    if (result.error) {
      setMessage(result.error.message);
      setSuccess(false);
    } else {
      setSuccess(true);
      const updatedPaymentMethodId = result.setupIntent.payment_method;
      dispatch(updateLastPaymentMethod(updatedPaymentMethodId));
      setClientSecret(""); // Close the dialog after successful payment method update
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={Boolean(clientSecret)}
      keepMounted
      TransitionComponent={Transition}
      onClose={() => setClientSecret("")}
      fullWidth
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#202940" : "#ffffff",
          boxShadow: "none",
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <MDBox display="flex" alignItems="center">
          <MDBox
            component="img"
            src={darkMode ? brandWhite : brandDark}
            alt="Brand"
            width="2rem"
            sx={{ mr: 1 }}
          />
          <MDBox>
            <MDTypography fontWeight="bold">Change Billing Method</MDTypography>
          </MDBox>
        </MDBox>
        <IconButton
          aria-label="close"
          onClick={() => setClientSecret("")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <MDBox component="form" id="payment-form" onSubmit={handleSubmit} sx={{ p: 3 }}>
        {clientSecret && <PaymentElement id="payment-element" />}
        <MDButton
          type="submit"
          variant="contained"
          color={darkMode ? "success" : "info"}
          disabled={!stripe || loading}
          fullWidth
        >
          {loading ? (
            <CircularProgress color={darkMode ? "info" : "success"} size={20} />
          ) : (
            "Change Billing Method"
          )}
        </MDButton>
        {message && (
          <MDBox
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon color={success ? "success" : "error"} sx={{ mr: 1 }}>
              {success ? "check_circle" : "error"}
            </Icon>
            <MDTypography color={success ? "success" : "error"}>{message}</MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Dialog>
  );
};

export default ChangePaymentForm;
