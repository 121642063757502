import { createSlice } from "@reduxjs/toolkit";

export const screen_monitor_task = createSlice({
  name: "screen_monitor_task",
  initialState: {
    CurrentTask: {},
    previousScreen: {},
    originScreenByTimestamp: {},
    diffScreen: {},
    MemberShip: {},
  },
  reducers: {
    initScreenReducer: (state) => {
      state.previousScreen = {};
      state.originScreenByTimestamp = {};
      state.diffScreen = {};
    },
    setVisualScreenTaskReducer: (state, action) => {
      state.CurrentTask = action?.payload;
    },
    setScreenByTimestampReducer: (state, action) => {
      state.originScreenByTimestamp = action?.payload;
    },
    setDiffScreenReducer: (state, action) => {
      state.diffScreen = action?.payload;
    },
    setPreviousScreenReducer: (state, action) => {
      state.previousScreen = action?.payload;
    },
    setMemberShipReducer: (state, action) => {
      state.MemberShip = action?.payload;
    },
  },
});
export const {
  initScreenReducer,
  setVisualScreenTaskReducer,
  setScreenByTimestampReducer,
  setDiffScreenReducer,
  setPreviousScreenReducer,
  setMemberShipReducer,
} = screen_monitor_task.actions;

export default screen_monitor_task.reducer;
