import React, { useState } from "react";
// Stripe import
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
// Mui components
import Slide from "@mui/material/Slide";
import { CircularProgress, Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mui/material/Icon";
// Material Kit 2 React components
import MDBox from "../../../../../../components/MDBox";
import MDButton from "../../../../../../components/MDButton";
import brandWhite from "../../../../../../assets/images/logos/logowhite.svg";
import brandDark from "../../../../../../assets/images/logos/logodark.svg";
import MDTypography from "../../../../../../components/MDTypography";
import { useAxiosPrivate } from "../../../../../../hooks/useAxiosPrivate";

import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentForm = ({
  clientSecret,
  darkMode,
  setClientSecret,
  brandName,
  subscriptionType,
  onSuccess,
  subscriptionMonth,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const apiPrivate = useAxiosPrivate();

  const options = {
    clientSecret: clientSecret,
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !clientSecret) {
      setMessage("Stripe has not yet initialized.");
      setLoading(false);
      toast.error("Stripe has not yet initialized.");
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setMessage(submitError.message);
      setSuccess(false);
      setLoading(false);
      toast.error(submitError.message);
      return;
    }

    let result;

    try {
      if (subscriptionType === "Freemium") {
        result = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url: "https://localhost:3000/payment/success",
          },
          clientSecret: clientSecret,
        });
      } else {
        result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: "https://localhost:3000/payment/success",
          },
          redirect: "if_required",
          clientSecret: clientSecret,
        });
      }

      if (result.error) {
        setMessage(result.error.message);
        setSuccess(false);
        toast.error(result.error.message);
      } else {
        try {
          if (subscriptionType === "Freemium") {
            await apiPrivate.post("/payment/webhook", {
              setupIntentId: result.setupIntent.id,
              subscriptionType: subscriptionType,
              subscriptionMonth: subscriptionMonth,
            });
          } else {
            await apiPrivate.post("/payment/webhook", {
              paymentIntentId: result.paymentIntent.id,
              subscriptionType: subscriptionType,
              subscriptionMonth: subscriptionMonth,
            });
          }
          setMessage(
            subscriptionType === "Freemium"
              ? "Payment information verified successfully!"
              : "Payment successful!"
          );
          setSuccess(true);
          onSuccess();
        } catch (apiError) {
          if (apiError.response && apiError.response.status === 500) {
            toast.error("Server error. Please try again later.");
          } else {
            toast.error("An unexpected error occurred. Please try again.");
          }
          setSuccess(false);
        }
      }
    } catch (error) {
      toast.error("An error occurred while processing the payment.");
      setMessage("An error occurred. Please try again.");
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={Boolean(clientSecret)}
      keepMounted
      TransitionComponent={Transition}
      onClose={() => {
        setClientSecret("");
      }}
      fullWidth
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#202940" : "#ffffff",
          boxShadow: "none",
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <MDBox display="flex" alignItems="center">
          <MDBox
            component="img"
            src={darkMode ? brandWhite : brandDark}
            alt="Brand"
            width="2rem"
            sx={{ mr: 1 }}
          />
          <MDBox display="flex" sx={{ justifyContent: "center" }}>
            <MDTypography fontWeight="bold" sx={{ width: "max-content" }}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setClientSecret("");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <MDBox component="form" id="payment-form" onSubmit={handleSubmit} sx={{ p: 0.5 }}>
        {clientSecret && <PaymentElement id="payment-element" options={options} />}
        <MDButton
          type="submit"
          variant="contained"
          color={darkMode ? "success" : "info"}
          disabled={!stripe}
          sx={{ mt: 2, mb: 2 }}
          fullWidth
        >
          {loading && (
            <CircularProgress color={darkMode ? "info" : "success"} sx={{ mr: 1 }} size={20} />
          )}
          {subscriptionType === "Freemium" ? "Verify payment info" : "Pay now"}
        </MDButton>
        {message && (
          <MDBox
            sx={{
              mt: 1,
              mb: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon color={success ? "success" : "error"} sx={{ mr: 1 }}>
              {success ? "check_circle" : "error"}
            </Icon>
            <MDTypography
              color={success ? "success" : "error"}
              sx={{ fontWeight: 10 }}
              variant="button"
            >
              {message}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Dialog>
  );
};

export default PaymentForm;
