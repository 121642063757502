import React from "react";
import { useSelector } from "react-redux";
import { useAxiosPrivate } from "../../../../../../hooks/useAxiosPrivate";
// Mui components
import Slide from "@mui/material/Slide";
import { Dialog, DialogTitle, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
// Material Kit 2 React components
import MDBox from "../../../../../../components/MDBox";
import MDButton from "../../../../../../components/MDButton";
import brandWhite from "../../../../../../assets/images/logos/logowhite.svg";
import brandDark from "../../../../../../assets/images/logos/logodark.svg";
import MDTypography from "../../../../../../components/MDTypography";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = [
  {
    label: "Verify your email address",
    description: `Please confirm that you want to use this as your education account email address. Once it's done you will be able to start.`,
    msg: "If you have any issues, please contact our support team at support@quantumsquint.com or call us at (314) 896-0095.",
  },
  {
    label: "Verify its you.",
    description: "We sent verification code. \n Please check your inbox and enter the code below.",
  },
  {
    label: "Email Verified",
    description: "Your email was verified. \n You can continue using the application.",
  },
];

const formatDescription = (description) => {
  return description.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const EmailVerificationStepper = (props) => {
  const {
    darkMode,
    openMailVerify,
    setOpenMailVerify,
    brandName,
    setClientSecret,
    // setOpenPayVerify,
  } = props;
  const length = 6;
  const email = useSelector((state) => state.auth.user.email);
  const [activeStep, setActiveStep] = React.useState(0);
  const [codeSent, setCodeSent] = React.useState(false);
  const [codeResent, setCodeResent] = React.useState(false);
  // const [verificationCode, setVerificationCode] = React.useState(null);
  const [userCode, setUserCode] = React.useState(new Array(length).fill(null));
  const inputRefs = React.useRef(Array.from({ length }, () => React.createRef()));
  const [errMsg, setErrMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const apiPrivate = useAxiosPrivate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const sendVerificationCode = async () => {
    try {
      setLoading(true); // Start loading before the request
      const response = await apiPrivate.post("/payment/send-verification-code", { email });
      if (response.status === 200) {
        setCodeSent(true);
        handleNext();
        setErrMsg("");
        setCodeResent(false);
      }
      // setVerificationCode(data.verificationCode.toString());
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setErrMsg("Too many verification attempts. Please try again later.");
      } else if (error.response && error.response.status === 400) {
        setErrMsg("Only educational emails are allowed for the freemium plan.");
      } else {
        setErrMsg("Error verifying code. Please try again.");
      }
    } finally {
      setLoading(false); // Stop loading regardless of the result
    }
  };

  const resendVerificationCode = async () => {
    try {
      setCodeResent(true);
      setLoading(true); // Start loading before the request
      const response = await apiPrivate.post("/payment/resend-verification-code", { email });
      if (response.status === 200) {
        setCodeSent(true);
        setErrMsg("");
      }
      // setVerificationCode(data.verificationCode.toString());
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setErrMsg("Too many verification attempts. Please try again later.");
      } else if (error.response && error.response.status === 400) {
        setErrMsg("Only educational emails are allowed for the freemium plan.");
      } else {
        setErrMsg("Error verifying code. Please try again.");
      }
    } finally {
      setLoading(false); // Stop loading regardless of the result
    }
  };

  const verifyCode = async () => {
    try {
      setLoading(true);
      const response = await apiPrivate.post("/payment/verify-code", {
        email,
        code: userCode.join(""),
      });
      if (response.status === 200) {
        handleNext(); // Proceed if successful
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setErrMsg("Too many verification attempts. Please try again later.");
      } else {
        setErrMsg("Error verifying code. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, position) => {
    const { value } = event.target;
    const newInputValues = [...userCode];
    newInputValues[position] = value.slice(0, 1); // Ensure only one digit can be entered

    setUserCode(newInputValues);

    // Auto-tab to next input field
    if (value.length > 0 && position < length - 1) {
      inputRefs.current[position + 1].current.focus();
    }
  };

  const handleBackspace = (event, position) => {
    if (event.key === "Backspace" && !userCode[position]) {
      // Focus previous field on backspace if current is empty
      if (position > 0) {
        inputRefs.current[position - 1].current.focus();
      }
    }
  };

  const handleClose = () => {
    setOpenMailVerify(false);
    setCodeSent(false);
    setActiveStep(0);
    setErrMsg("");
    setUserCode(new Array(length).fill(null));
  };

  const handlePaymentVerify = async () => {
    try {
      const response = await apiPrivate.post("/payment/create-payment-intent", {
        subscriptionType: "Freemium",
        email: email,
      });
      setOpenMailVerify(false);
      setCodeSent(false);
      setActiveStep(0);
      setErrMsg("");
      setUserCode(new Array(length).fill(null));
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      if (error?.response) {
        setErrMsg(error?.response?.data?.message);
      } else {
        setErrMsg("Error verifying code. Please try again.");
      }
    }
  };

  return (
    <Dialog
      open={openMailVerify}
      keepMounted
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#202940" : "#ffffff",
          MDBoxShadow: "none",
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <MDBox display="flex" alignItems="center">
          <MDBox
            component="img"
            src={darkMode ? brandWhite : brandDark}
            alt="Brand"
            width="2rem"
            sx={{ mr: 1 }}
          />
          <MDBox display="flex" sx={{ justifyContent: "center" }}>
            <MDTypography fontWeight="bold" sx={{ width: "max-content" }}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {activeStep === 2 ? (
        <MDBox sx={{ p: 0.5 }}>
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <Icon
              color={darkMode ? "success" : "info"}
              sx={{ fontSize: 100, mt: 1 }}
              fontSize="xlarge"
            >
              check_circle_icon
            </Icon>
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="h2">{steps[activeStep].label}</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              px: 2,
              mt: 2,
              mb: 2,
            }}
          >
            <MDTypography variant="body2">
              {formatDescription(steps[activeStep].description)}
            </MDTypography>
          </MDBox>
          {errMsg && (
            <MDBox
              sx={{
                mt: 1,
                mx: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon color="error" sx={{ mr: 1 }}>
                error_icon
              </Icon>
              <MDTypography color="error" sx={{ fontWeight: 10 }} variant="button">
                {errMsg}
              </MDTypography>
            </MDBox>
          )}
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              px: 2,
              mt: 1,
              mb: 5,
            }}
          >
            <MDButton
              size="large"
              color={darkMode ? "success" : "info"}
              onClick={handlePaymentVerify}
            >
              Next Step
            </MDButton>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox sx={{ p: 0.5, mb: 2 }}>
          <MDBox
            elevation={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              px: 2,
            }}
          >
            <MDTypography>{steps[activeStep].label}</MDTypography>
          </MDBox>
          <Divider />
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              px: 2,
            }}
          >
            <MDTypography variant="body2">
              {formatDescription(steps[activeStep].description)}
            </MDTypography>
          </MDBox>
          {errMsg && (
            <MDBox
              sx={{
                mt: 1,
                mx: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon color="error" sx={{ mr: 1 }}>
                error_icon
              </Icon>
              <MDTypography color="error" sx={{ fontWeight: 10 }} variant="button">
                {errMsg}
              </MDTypography>
            </MDBox>
          )}
          {!codeSent ? (
            <MDBox sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <MDButton
                size="large"
                color={darkMode ? "success" : "info"}
                onClick={sendVerificationCode}
                // fullWidth
              >
                {loading && <CircularProgress sx={{ mr: 1 }} color="info" size={20} />}
                Verify Email Address
              </MDButton>
            </MDBox>
          ) : (
            <MDBox sx={{ justifyContent: "center", px: 4, mt: 1 }}>
              <MDBox display="flex" justifyContent="center" sx={{ mb: 2 }}>
                {userCode.map((singleCode, index) => (
                  <TextField
                    key={index}
                    type="text"
                    value={singleCode}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: "center", width: "20px" },
                      ref: inputRefs.current[index],
                    }}
                    style={{ marginRight: "10px" }}
                  />
                ))}
              </MDBox>
              <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                <MDButton
                  size="large"
                  color={darkMode ? "success" : "info"}
                  onClick={verifyCode}
                  // fullWidth
                >
                  {!codeResent && loading && (
                    <CircularProgress sx={{ mr: 1 }} color="info" size={20} />
                  )}
                  Verify Code
                </MDButton>
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  mt: 2,
                  alignItems: "center",
                }}
              >
                <MDTypography
                  variant="body2"
                  color={darkMode ? "success" : "info"}
                  sx={{ cursor: "pointer" }}
                  onClick={resendVerificationCode}
                >
                  {codeResent && loading && (
                    <CircularProgress sx={{ mr: 1 }} color="info" size={15} />
                  )}
                  Resend code
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              px: 2,
              mt: 2,
            }}
          >
            <MDTypography color="secondary" variant="body2">
              {steps[activeStep].msg}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </Dialog>
  );
};

export default EmailVerificationStepper;
