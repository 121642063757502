import React from "react";
import { Card, CardContent, Chip } from "@mui/material";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon"; // Import for icons

const subscriptionIcons = {
  premium: "🥇",
  startup: "🥈",
  basic: "🥉",
};

export default function MyPlan() {
  const navigate = useNavigate();
  const MembershipStatus = useSelector((state) => state.workspace.MemberShip);

  if (!MembershipStatus || !MembershipStatus.activeSubscriptionType) {
    return (
      <MDBox mb={2} sx={{ flex: 1 }}>
        <MDBox mb={2}>
          <MDTypography variant="body2">
            <strong>My Plan</strong>
          </MDTypography>
          <MDTypography variant="caption" color={"secondary"}>
            Change your plan based on your needs
          </MDTypography>
        </MDBox>
        <Card sx={{ height: "75%", display: "flex", flexDirection: "column" }}>
          <CardContent
            sx={{
              flex: 1, // Ensure it stretches to the same height
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Icon color="primary" fontSize="large">
              account_circle
            </Icon>
            <MDTypography variant="h6" color="primary" sx={{ mt: 1 }}>
              No Active Membership
            </MDTypography>
            <MDTypography variant="body2" sx={{ mb: 1 }}>
              You currently do not have an active membership. Please explore our available plans.
            </MDTypography>
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/payment")}
              sx={{ mt: 1 }}
            >
              Explore Plans
            </MDButton>
          </CardContent>
        </Card>
      </MDBox>
    );
  }

  return (
    <MDBox mb={2} sx={{ flex: 1 }}>
      <MDBox mb={2}>
        <MDTypography variant="body2">
          <strong>My Plan</strong>
        </MDTypography>
        <MDTypography variant="caption" color={"secondary"}>
          Change your plan based on your needs
        </MDTypography>
      </MDBox>
      <Card sx={{ height: "75%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: 1 }}>
          <MDBox sx={{ display: "flex", alignItems: "center", mb: 0.5, mt: 2 }}>
            <MDTypography variant="h4" color="success" sx={{ mr: 1, ml: -1 }}>
              {subscriptionIcons[MembershipStatus.activeSubscriptionType]}
              {MembershipStatus.activeSubscriptionType.charAt(0).toUpperCase() +
                MembershipStatus.activeSubscriptionType.slice(1)}
            </MDTypography>
            <Chip label={`billed ${MembershipStatus.activeOption}`} size="small" color="info" />
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <MDTypography variant="body1" sx={{ mr: 1 }}>
              {MembershipStatus.activeSubscriptionType === "premium" &&
              MembershipStatus.activeOption === "yearly"
                ? "$ 179.99"
                : MembershipStatus.activeSubscriptionType === "premium" &&
                    MembershipStatus.activeOption === "monthly"
                  ? "$ 19.99"
                  : MembershipStatus.activeSubscriptionType === "startup" &&
                      MembershipStatus.activeOption === "yearly"
                    ? "$ 79.99"
                    : MembershipStatus.activeSubscriptionType === "startup" &&
                        MembershipStatus.activeOption === "monthly"
                      ? "$ 9.99"
                      : MembershipStatus.activeSubscriptionType === "freemium"
                        ? "$ 79.99"
                        : null}
            </MDTypography>
            <MDTypography variant="button" color="secondary">
              {`Next renew ${new Date(MembershipStatus.activeExpirationDate).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }
              )}`}
            </MDTypography>
          </MDBox>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item>
              <MDButton
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/payment");
                }}
              >
                Explore Plans
              </MDButton>
            </Grid>
            {/*<Grid item>*/}
            {/*  <MDButton variant="outlined" color="secondary">*/}
            {/*    Manage Plans*/}
            {/*  </MDButton>*/}
            {/*</Grid>*/}
          </Grid>
        </CardContent>
      </Card>
    </MDBox>
  );
}
