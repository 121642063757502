import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import SelectPanel from "./sections/SelectPanel";
import DetailsPanel from "./sections/DetailsPanel";
import EditTaskModal from "./sections/EditTaskModal";
import DeleteTaskModal from "./sections/DeleteTaskModal";
import CustomizedInputSearch from "./sections/CustomizedInputSearch";
import ExpiredPage from "./sections/ExpiredPage";
import NewTaskModal from "./sections/NewTaskModal";
import CompareModal from "./sections/CompareModal";
import DashboardLayout from "../../../LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "../../../components/MDTypography";

// actions for workspaces
import { useMaterialUIController } from "context";
import {
  createTask,
  deleteTask,
  handleUpdateTaskById,
  getMembership,
  getTaskById,
  getTasks,
} from "../store/action";

function CodebaseMonitoring() {
  const MemberShip = useSelector((state) => state.workspace.MemberShip);
  const [url, setUrl] = useState("");
  const [selectById, setSelectById] = useState(null);
  const [newTask, setNewTask] = useState(null);
  const [editTask, setEditTask] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState({ id: null, value: false });
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [loading, setLoading] = useState(false); // Loading state

  const dispatch = useDispatch();

  const handleAlertModal = (id) => setShowAlertModal({ id: id, value: !showAlertModal.value });

  const handleParentSelect = (uuid) => {
    setSelectById(uuid);
  };

  const ToogleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const ToogleAddTaskModal = () => {
    setOpenAddTaskModal(!openAddTaskModal);
  };

  const handleAddTask = async (newtask) => {
    try {
      await dispatch(createTask(newtask)); // Use await to ensure the task is fully dispatched
      ToogleAddTaskModal();
    } catch (error) {
      console.error("Failed to add task:", error);
    }
  };

  const handleUpdateTask = async (editTask) => {
    try {
      const newtask = {
        title: editTask.title,
        url: editTask.url,
        frequency: editTask.frequency,
      };
      await dispatch(handleUpdateTaskById(editTask.uuid, newtask)); // Use await to ensure the task is fully dispatched
      await dispatch(getTaskById(selectById));
      ToogleEditModal(editTask.id);
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  const hadleRemoveItem = async (id) => {
    try {
      await dispatch(deleteTask(id)); // Use await to ensure the task is fully dispatched
      handleAlertModal(id);
    } catch (error) {
      console.error("Failed to remove task:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        await dispatch(getTasks());
        await dispatch(getMembership());
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchData();
  }, [dispatch]);

  if (loading) {
    return (
      <DashboardLayout>
        <MDBox
          sx={{
            position: "absolute", // Positioning the box absolutely
            top: "100%", // Vertically center the box
            left: "50%", // Horizontally center the box
            transform: "translate(-50%, 120%)", // Adjust position to be fully centered
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CircularProgress size={60} sx={{ mb: 2 }} color="secondary" />
          <MDTypography variant="h5" sx={{ mb: 1 }}>
            Loading your workspace...
          </MDTypography>
          <MDTypography variant="body2">
            Please wait while we prepare everything for you.
          </MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {!MemberShip.activeSubscriptionType ? (
        <ExpiredPage />
      ) : (
        <MDBox>
          <CustomizedInputSearch
            darkMode={darkMode}
            url={url}
            setUrl={setUrl}
            openAddTaskModal={openAddTaskModal}
            newTask={newTask}
            setNewTask={setNewTask}
            ToogleAddTaskModal={ToogleAddTaskModal}
          />
          <Grid container spacing={2}>
            <Grid md={6} xs={12}>
              <SelectPanel
                darkMode={darkMode}
                handleParentSelect={handleParentSelect}
                setEditTask={setEditTask}
                selectById={selectById}
                ToogleEditModal={ToogleEditModal}
                handleAlertModal={handleAlertModal}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <DetailsPanel
                darkMode={darkMode}
                selectById={selectById}
                setShowHistoryModal={setShowHistoryModal}
                showHistoryModal={showHistoryModal}
              />
            </Grid>
          </Grid>
          <EditTaskModal
            darkMode={darkMode}
            editTask={editTask}
            setEditTask={setEditTask}
            openEditModal={openEditModal}
            ToogleEditModal={ToogleEditModal}
            handleUpdateTask={handleUpdateTask}
          />
          <NewTaskModal
            darkMode={darkMode}
            newTask={newTask}
            setNewTask={setNewTask}
            openAddTaskModal={openAddTaskModal}
            ToogleAddTaskModal={ToogleAddTaskModal}
            handleAddTask={handleAddTask}
          />
          <DeleteTaskModal
            darkMode={darkMode}
            showAlertModal={showAlertModal}
            handleAlertModal={handleAlertModal}
            hadleRemoveItem={hadleRemoveItem}
          />
          <CompareModal
            darkMode={darkMode}
            showHistoryModal={showHistoryModal}
            setShowHistoryModal={setShowHistoryModal}
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default CodebaseMonitoring;
