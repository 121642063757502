import { useAxiosPrivate } from "../../../hooks/useAxiosPrivate";

const apiPrivate = useAxiosPrivate();

export const handleSendVerificationCode = async ({ email }) => {
  try {
    return await apiPrivate.post("/auth/send-verification-code", { email });
  } catch (error) {
    throw new Error(error.response.data || "An error occurred.");
  }
};

export const handleVerifyCode = async ({ email, code }) => {
  try {
    return await apiPrivate.post("/auth/verify-code", { email, code });
  } catch (error) {
    throw new Error(error.response.data || "An error occurred.");
  }
};

export const handleResendVerificationCode = async ({ email }) => {
  try {
    return await apiPrivate.post("/auth/resend-verification-code", { email });
  } catch (error) {
    throw new Error(error.response.data || "An error occurred.");
  }
};

export const SendResetPassword = async ({ email, newPassword }) => {
  try {
    return await apiPrivate.post("/auth/reset-password", { email, newPassword });
  } catch (error) {
    throw new Error(error.response.data || "An error occurred.");
  }
};
