import * as api from "./api";
import * as reducer from "./reducer";
import { toast } from "react-toastify";

export const getTransactions = () => async (dispatch) => {
  try {
    const { data } = await api.getTransactions();
    dispatch(reducer.setTransactionsReducer(data.transactions));
    dispatch(reducer.setLastPaymentMethodReducer(data.lastPaymentMethod));
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
  }
};

export const updateLastPaymentMethod = (paymentMethodId) => async (dispatch) => {
  try {
    const { data } = await api.updateLastPaymentMethod(paymentMethodId);
    dispatch(reducer.setLastPaymentMethodReducer(data.lastPaymentMethod));
    toast.success("Billing method updated successfully.");
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
  }
};
