import * as React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import { Card, CircularProgress } from "@mui/material"; // Import CircularProgress

function DeleteTaskModal({ showAlertModal, handleAlertModal, hadleRemoveItem }) {
  const [loading, setLoading] = useState(false); // Add loading state

  return (
    <Modal
      open={showAlertModal.value}
      // onClose={() => {
      //   handleAlertModal(showAlertModal.id);
      // }}
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Slide direction="down" in={showAlertModal.value} timeout={500}>
        <MDBox
          position="relative"
          width="500px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="dark"
          shadow="xl"
        >
          <MDBox display="flex" alignItems="center" justifyContent="start" p={2}>
            <Card
              display="flex"
              alignItems="center"
              sx={{ mr: 1, p: 1, bgcolor: "rgb(255 236 235)" }}
            >
              <Icon color={"primary"} fontSize={"large"}>
                error_outline
              </Icon>
            </Card>
            <MDTypography variant="h5">This action is permanent.</MDTypography>
          </MDBox>
          <Divider sx={{ my: 0 }} />
          <MDBox p={2}>
            <MDTypography variant="body2" color="secondary" fontWeight="regular">
              You will not be able to recover this job.
            </MDTypography>
          </MDBox>
          <Divider sx={{ my: 0 }} />
          <MDBox display="flex" justifyContent="end" p={1.5}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                handleAlertModal(showAlertModal.id);
              }}
              size={"large"}
              sx={{ mr: 1, fontSize: "large" }}
              disabled={loading} // Disable button while loading
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="primary"
              sx={{ fontSize: "large" }}
              onClick={async () => {
                setLoading(true); // Start loading
                try {
                  await hadleRemoveItem(showAlertModal.id); // Wait for item removal
                } finally {
                  setLoading(false); // Stop loading
                  handleAlertModal(showAlertModal.id); // Close modal after removal
                }
              }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} color="secondary" />
                  Deleting...
                </>
              ) : (
                "Yes Delete"
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Slide>
    </Modal>
  );
}

export default DeleteTaskModal;
