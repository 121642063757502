import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import CoverLayout from "../../../../LayoutContainers/CoverLayout";
import bgImage from "../../../../assets/images/bg-reset-cover.jpeg";
import Grid from "@mui/material/Grid";

// Import API functions
import {
  handleSendVerificationCode,
  handleVerifyCode,
  handleResendVerificationCode,
  SendResetPassword,
} from "../../api";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(""));
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [step, setStep] = useState("request"); // "request", "verify", "reset"
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isResend, setIsResend] = useState(false);
  const inputRefs = useRef(verificationCode.map(() => React.createRef()));

  const handleRequestReset = async () => {
    setLoading(true);
    setMessage(""); // Clear any previous messages
    try {
      await handleSendVerificationCode({ email });
      setStep("verify");
      setError(false);
      setMessage("Verification code sent. Please check your email.");
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    setMessage(""); // Clear any previous messages
    try {
      const code = verificationCode.join("");
      await handleVerifyCode({ email, code });
      setError(false);
      setMessage("Email verified successfully. Please enter your new password.");
      setStep("reset"); // Move to password reset step
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    setLoading(true);
    setIsResend(true);
    setMessage(""); // Clear any previous messages
    try {
      await handleResendVerificationCode({ email });
      setError(false);
      setMessage("Verification code resent successfully.");
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
      setIsResend(false);
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    setMessage(""); // Clear any previous messages
    try {
      await SendResetPassword({ email, newPassword });
      setMessage("Password reset successfully.");
      setError(false);
      toast.success("Password reset successfully.");
      setVerificationCode(new Array(6).fill(""));
      setNewPassword("");
      setEmail("");
      // Redirect to login or any other appropriate page
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
      setStep("request");
      setMessage("");
    }
  };

  const handleChange = (event, index) => {
    const value = event.target.value.slice(0, 1);
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    // Auto-tab to next input field
    if (value && index < 5) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleBackspace = (event, index) => {
    if (event.key === "Backspace" && !verificationCode[index] && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handleErrorMessage = (error) => {
    setMessage(error.message);
    setError(true);
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <Card sx={{ width: "50vh" }}>
          <MDBox borderRadius="lg" mx={2} mt={3} mb={1} textAlign="center">
            <MDTypography variant="h3" fontWeight="medium" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" my={1}>
              {step === "request"
                ? "You will receive an e-mail in maximum 60 seconds"
                : step === "verify"
                  ? "Enter the verification code you received"
                  : "Enter your new password"}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              {step === "request" && (
                <MDBox mb={4}>
                  <MDInput
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </MDBox>
              )}
              {step === "verify" && (
                <>
                  <MDBox display="flex" justifyContent="center" sx={{ mb: 2 }}>
                    {verificationCode.map((singleCode, index) => (
                      <TextField
                        key={index}
                        value={singleCode}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: "center", width: "20px" },
                          ref: inputRefs.current[index],
                        }}
                        style={{ marginRight: "10px" }}
                      />
                    ))}
                  </MDBox>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      alignItems: "center",
                    }}
                  >
                    <MDTypography
                      variant="body2"
                      color="success"
                      sx={{ cursor: "pointer" }}
                      onClick={handleResend}
                    >
                      {loading && isResend && (
                        <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />
                      )}
                      Resend Code
                    </MDTypography>
                  </MDBox>
                </>
              )}
              {step === "reset" && (
                <MDBox mb={4}>
                  <MDInput
                    type="password"
                    label="New Password"
                    variant="standard"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </MDBox>
              )}
              <MDBox mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                {step === "request" && (
                  <MDButton variant="gradient" color="info" onClick={handleRequestReset}>
                    {loading && !isResend && (
                      <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />
                    )}
                    Send Verification Code
                  </MDButton>
                )}
                {step === "verify" && (
                  <MDButton variant="gradient" color="info" onClick={handleVerify}>
                    {loading && <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />}
                    Verify Code
                  </MDButton>
                )}
                {step === "reset" && (
                  <MDButton variant="gradient" color="info" onClick={handleResetPassword}>
                    {loading && <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />}
                    Reset Password
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
            {message && (
              <MDBox sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <MDTypography variant="caption" color={error ? "error" : "success"}>
                  {message}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </Grid>
    </CoverLayout>
  );
}

export default ResetPassword;
