// ** Reducers Imports
import workspace from "../pages/CodebaseMonitoring/store/reducer";
import screen_monitor_task from "../pages/VisualSnapshot/store/reducer";
import payment from "../pages/billing/store/reducer";
// import auth from "./authentication/reducer";
import auth from "./auth/authSlice";
// import socket from "./socket/socketSlice";

const rootReducer = {
  workspace,
  auth,
  screen_monitor_task,
  // socket,
  payment,
};

export default rootReducer;
