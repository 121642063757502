import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMaterialUIController } from "../../../../context";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../../../LayoutContainers/BasicLayout";

// Images
import bgImage from "../../../../assets/images/bg-sign-in-basic.jpeg";
// import Logo from "../../../../assets/images/logos/leapARMM_logo.png";
import brandWhite from "../../../../assets/images/logos/logowhite.svg";
import brandDark from "../../../../assets/images/logos/logodark.svg";
import { login } from "../../../../redux/auth/authSlice";
import CircularProgress from "@mui/material/CircularProgress";

function SignIn({ brandName }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [loading, setLoading] = useState(false); // Loading state
  // const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onSubmit = async () => {
    setLoading(true); // Start loading
    try {
      await dispatch(login({ email, password }));
    } catch (error) {
      console.error("Failed to sign in:", error);
    } finally {
      setLoading(false); // Stop loading after the request completes
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ position: "absolute", top: "20%", width: 350 }}>
        <MDBox borderRadius="lg" mx={5} mt={5} display="flex" sx={{ justifyContent: "center" }}>
          <MDBox display="flex" alignItems="center">
            <MDBox
              component="img"
              src={darkMode ? brandWhite : brandDark}
              alt="Brand"
              width="4rem"
              sx={{ mr: 1 }}
            />
            <MDBox display="flex" sx={{ justifyContent: "center" }}>
              <MDTypography variant="h3" fontWeight="bold" sx={{ width: "max-content" }}>
                {brandName}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form" mt={2}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(eve) => {
                  setEmail(eve.target.value);
                }}
                value={email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                onChange={(eve) => {
                  setPassword(eve.target.value);
                }}
                value={password}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" size="large" onClick={onSubmit} fullWidth>
                {loading && <CircularProgress color="light" size={20} sx={{ mr: 1 }} />}
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="success"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
