import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAxiosPrivate } from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
// Children components
import DefaultPriceCard from "./components/PricingCards";
import PaymentForm from "./components/PaymentForm";
import EmailVerificationStepper from "./components/EmailVerificationStepper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SubscriptionServices(props) {
  const { darkMode } = props;
  const [clientSecret, setClientSecret] = useState("");
  const [selectedPriceOption, setselectedPriceOption] = useState(null);
  const [openMailVerify, setOpenMailVerify] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.auth.user.email);
  const apiPrivate = useAxiosPrivate();

  const navigate = useNavigate();

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const services = [
    {
      badge: { color: "secondary", label: "Basic" },
      price: {
        currency: "$",
        value: tabType === "annual" ? 1250 : 100,
        type: tabType === "annual" ? "yr" : "mo",
      },
      specifications: [
        { label: "Scan 5 Websites", includes: true },
        { label: "Pixel regression testing", includes: false },
        { label: "Code Script malware detection", includes: false },
        // { label: "API Access", includes: false },
        // { label: "Complete documentation", includes: false },
      ],
      action: {
        type: "internal",
        active: false,
        route: "/",
        color: "secondary",
        label: "Buy Now",
        disabled: false,
      },
    },
    {
      badge: { color: darkMode ? "secondary" : "secondary", label: "Startup" },
      price: {
        currency: "$",
        value: tabType === "annual" ? 2500 : 200,
        type: tabType === "annual" ? "yr" : "mo",
      },
      specifications: [
        { label: "Scan 20 Websites", includes: true },
        { label: "Pixel regression testing", includes: true },
        { label: "Code Script malware detection", includes: false },
        // { label: "API Access", includes: false },
        // { label: "Complete documentation", includes: false },
      ],
      action: {
        type: "internal",
        active: true,
        route: "/",
        color: darkMode ? "secondary" : "secondary",
        label: "Buy Now",
        disabled: false,
      },
    },
    {
      badge: { color: "secondary", label: "Premium" },
      price: {
        currency: "$",
        value: tabType === "annual" ? 5000 : 400,
        type: tabType === "annual" ? "yr" : "mo",
      },
      specifications: [
        { label: "Unlimited Websites", includes: true },
        { label: "Pixel regression testing", includes: true },
        { label: "Code Script malware detection", includes: true },
        // { label: "API Access", includes: true },
        // { label: "Complete documentation", includes: true },
      ],
      action: {
        type: "internal",
        active: false,
        route: "/",
        color: "secondary",
        label: "Coming Soon",
        disabled: false,
      },
    },
  ];

  useEffect(() => {
    const fetchClientSecret = async () => {
      if (selectedPriceOption) {
        setLoading(true);
        try {
          if (selectedPriceOption.type === "Freemium") {
            setOpenMailVerify(true);
          } else {
            const response = await apiPrivate.post("/payment/create-payment-intent", {
              price: selectedPriceOption.amount,
              subscriptionType: selectedPriceOption.type,
              currency: selectedPriceOption.currency,
              email: email,
            });
            setClientSecret(response.data.clientSecret);
          }
        } catch (error) {
          console.error("Error creating payment intent", error);
          toast.error(error?.response?.data);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchClientSecret();
  }, [selectedPriceOption]);

  return (
    <>
      <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          md={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MDTypography variant="h3" mb={2}>
            See our pricing
          </MDTypography>
          <MDTypography variant="body2">
            You have Free Unlimited Updates and Premium Support on each package.
          </MDTypography>
        </Grid>
      </Container>
      <MDBox mt={-16}>
        <Container>
          <Grid container sx={{ mb: 6 }}>
            <Grid item xs={7} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id="monthly"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MDBox>
                    }
                  />
                  <Tab
                    id="annual"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Annual
                      </MDBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <MDBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              {services.map((service, key) => (
                <Grid item xs={12} lg={4} key={key}>
                  <DefaultPriceCard
                    setselectedPriceOption={setselectedPriceOption}
                    badge={service.badge}
                    specifications={service.specifications}
                    price={service.price}
                    action={service.action}
                    loading={selectedPriceOption?.type === service.badge.label ? loading : false}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </Container>
      </MDBox>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: {
              theme: darkMode ? "night" : "float",
            },
          }}
        >
          <PaymentForm
            clientSecret={clientSecret}
            subscriptionType={selectedPriceOption.type}
            subscriptionMonth={selectedPriceOption.month}
            setClientSecret={setClientSecret}
            darkMode={darkMode}
            brandName="LeapARMM"
            onSuccess={() => navigate("/payment/success")}
          />
        </Elements>
      )}
      <EmailVerificationStepper
        darkMode={darkMode}
        openMailVerify={openMailVerify}
        setOpenMailVerify={setOpenMailVerify}
        setClientSecret={setClientSecret}
        brandName="LeapARMM"
      />
    </>
  );
}

export default SubscriptionServices;
