import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Icon from "@mui/material/Icon";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import { generateInvoice } from "../../../store/api";

// Map Stripe payment method types to icons
const paymentTypeIcons = {
  card: "credit_card",
  ach_credit_transfer: "account_balance",
  ach_debit: "account_balance_wallet",
  us_bank_account: "account_balance",
  alipay: "payment",
  // Add more mappings as needed
};

// Map icons for each field
const fieldIcons = {
  paidDate: "calendar_today",
  successStatus: "check_circle",
  paymentType: "payment",
  paymentAmount: "attach_money",
};

function Transaction(props) {
  const {
    transactionId, // Added this prop to use the ID for generating invoices
    color,
    icon,
    name,
    description,
    value,
    paidDate,
    successStatus,
    paymentType,
    darkMode,
  } = props;

  // Format the date to a more readable format
  const formattedPaidDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(paidDate));

  // Get the icon for the payment type
  const paymentTypeIcon = paymentTypeIcons[paymentType] || "payment";

  // Format the payment type to be more readable
  const formattedPaymentType = paymentType
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  // Handle invoice generation and download
  const handleInvoiceDownload = async () => {
    await generateInvoice(transactionId);
  };

  return (
    <Accordion sx={{ background: darkMode ? "#2a2b2f" : "white", mt: 0.5 }}>
      <AccordionSummary
        expandIcon={
          <MDBox display="flex" alignItems="center">
            <MDButton variant="outlined" color={color} iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          </MDBox>
        }
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row-reverse",
          mt: 0.5,
        }}
      >
        <MDBox display="flex" alignItems="center" width="100%" ml={2}>
          <MDBox display="flex" flexDirection="column" flexGrow={1}>
            <MDTypography variant="button" fontWeight="medium" gutterBottom>
              {name}
            </MDTypography>
            <MDTypography variant="caption" fontWeight="regular">
              {description}
            </MDTypography>
          </MDBox>
          <MDTypography
            variant="button"
            color={color}
            fontWeight="medium"
            textGradient
            sx={{ mr: 2 }}
          >
            {value}
          </MDTypography>
        </MDBox>
      </AccordionSummary>
      <AccordionDetails>
        <MDBox display="flex" flexDirection="column" pl={4}>
          <MDBox display="flex" alignItems="center" mb={1}>
            <Icon sx={{ mr: 1, color: "info.main" }}>{fieldIcons.paidDate}</Icon>
            <MDTypography variant="body2" fontWeight="regular">
              Paid Date: {formattedPaidDate}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={1}>
            <Icon
              sx={{ mr: 1, color: successStatus === "succeeded" ? "success.main" : "error.main" }}
            >
              {fieldIcons.successStatus}
            </Icon>
            <MDTypography variant="body2" fontWeight="regular">
              Success Status: {successStatus}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={1}>
            <Icon sx={{ mr: 1, color: "warning.main" }}>{paymentTypeIcon}</Icon>
            <MDTypography variant="body2" fontWeight="regular">
              Payment Type: {formattedPaymentType}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={1}>
            <Icon sx={{ mr: 1, color: "success.main" }}>{fieldIcons.paymentAmount}</Icon>
            <MDTypography variant="body2" fontWeight="regular">
              Payment Amount: {value}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDButton
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                width: "fit-content",
                px: 3,
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleInvoiceDownload}
            >
              <Icon fontSize="small" sx={{ mr: 1 }}>
                download
              </Icon>
              <MDTypography variant="button" fontWeight="medium" color="white">
                Download PDF
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </AccordionDetails>
    </Accordion>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  transactionId: PropTypes.string.isRequired, // Added transactionId prop type
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  paidDate: PropTypes.string.isRequired,
  successStatus: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default Transaction;
