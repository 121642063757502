import { createSlice } from "@reduxjs/toolkit";

export const payment = createSlice({
  name: "payment",
  initialState: {
    TransactionsHistory: [],
    lastPaymentMethod: {},
  },
  reducers: {
    setTransactionsReducer: (state, action) => {
      state.TransactionsHistory = action.payload;
    },
    setLastPaymentMethodReducer: (state, action) => {
      state.lastPaymentMethod = action.payload;
    },
  },
});
export const { setTransactionsReducer, setLastPaymentMethodReducer } = payment.actions;

export default payment.reducer;
