import * as React from "react";

import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Container from "@mui/material/Container";

// eslint-disable-next-line react/prop-types
function ExpiredPage() {
  return (
    <MDBox sx={{ height: "80vh" }}>
      <Container
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBox
          sx={{
            width: "100%",
          }}
        >
          <MDBox sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <MDTypography variant="h2">Your subscription has expired</MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography color="secondary" variant="body2">
              Please new to continue using premium features.
            </MDTypography>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default ExpiredPage;
