// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import CoverLayout from "../../../../LayoutContainers/CoverLayout";

function ContactUs() {
  return (
    <>
      <CoverLayout image={null}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDBox
              display={{ xs: "none", lg: "flex" }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{ backgroundImage: `url(${bgImage})` }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MDBox
              // bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MDBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MDTypography variant="h3" color="white">
                  Contact us
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <MDTypography variant="body2" mb={3}>
                  For further questions, including partnership opportunities, please email
                  hello@creative-tim.com or contact using our contact form.
                </MDTypography>
                <MDBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDInput
                        variant="standard"
                        label="What can we help you?"
                        placeholder="Describe your problem in at least 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MDButton type="submit" variant="gradient" color="info">
                      Send Message
                    </MDButton>
                  </Grid>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </CoverLayout>
    </>
  );
}

export default ContactUs;
