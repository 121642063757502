import * as React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
// import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";
import validator from "validator";

function CustomizedInputSearch(props) {
  const { url, setUrl, ToogleAddTaskModal, setNewTask, darkMode } = props;
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleChange = (event) => {
    setUrl(event.currentTarget.value);
  };

  function convertTextToLinks(text) {
    const urlRegex = /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+|[\w-]+\.com[^\s]*)/g;
    return text.replace(urlRegex, (match, space, url) => {
      if (url.startsWith("www.")) {
        url = `https://${url}`;
      } else if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://www.${url}`;
      }
      return url;
    });
  }

  const handleAddJob = () => {
    if (validator.isURL(url)) {
      setNewTask({
        // id: items.length + 1,
        url: convertTextToLinks(url),
        title: url,
        frequency: "every day",
      });
      ToogleAddTaskModal();
      setUrl("");
      setErrorMessage("");
    } else {
      setErrorMessage("Is Not Valid URL");
    }
    if (url.length == 0) {
      setErrorMessage("Is Not Empty URL");
    }
  };

  return (
    <>
      <MDBox sx={{ mb: 2 }}>
        <MDBox sx={{ mb: 3, display: "flex" }}>
          <TextField
            id="standard-adornment-text"
            sx={{ mr: 0.5 }}
            type={"text"}
            autoFocus
            required
            fullWidth
            error={errorMessage !== "" ? true : false}
            placeholder={"Enter a website: www.google.com"}
            helperText={errorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={url}
            onChange={handleChange}
          />
          <MDBox>
            <MDButton
              type={"submit"}
              variant="contained"
              color={darkMode ? "success" : "info"}
              sx={{ alignItems: "center", display: "flex", minWidth: "max-content" }}
              onClick={handleAddJob}
            >
              <Icon sx={{ mr: 0.5 }}>add_task</Icon>
              <MDTypography color={"white"} variant={"button"}>
                Add New Job
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default CustomizedInputSearch;
