import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { axiosPrivate } from "../../utils/axios";

// Initial state
const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  accessToken: localStorage.getItem("accessToken"),
  user: JSON.parse(localStorage.getItem("user")),
};

// Initialize authentication state
export const initializeAuth = createAsyncThunk("auth/initialize", async (_, { dispatch }) => {
  const accessToken = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("user"));

  if (accessToken && user) {
    try {
      const decodedUser = jwtDecode(accessToken);
      if (decodedUser.exp * 1000 > Date.now()) {
        dispatch(
          authSlice.actions.initialize({
            isAuthenticated: true,
            accessToken,
            user: decodedUser,
          })
        );
      } else {
        const refreshedData = await dispatch(refresh()).unwrap();
        dispatch(
          authSlice.actions.initialize({
            isAuthenticated: true,
            accessToken: refreshedData.accessToken,
            user: refreshedData.user,
          })
        );
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      dispatch(logout()); // Handle invalid token
    }
  }

  return { accessToken, user };
});

// Handle user login
export const login = createAsyncThunk("auth/login", async (credentials, { dispatch }) => {
  try {
    const response = await axiosPrivate.post("/auth/login", credentials);
    const { accessToken } = response.data;
    const user = jwtDecode(accessToken);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(user));

    dispatch(
      authSlice.actions.initialize({
        isAuthenticated: true,
        accessToken,
        user,
      })
    );

    dispatch(startTokenMonitor());
    return { accessToken, user };
  } catch (error) {
    if (error.response) {
      toast.error(error?.response?.data?.message || error.message);
    } else {
      toast.error(error.message);
    }
    throw error;
  }
});

// Refresh the access token
export const refresh = createAsyncThunk("auth/refresh", async () => {
  try {
    const response = await axiosPrivate.get("/auth/refresh", {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });
    const { accessToken } = response.data;
    const user = jwtDecode(accessToken);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(user));
    return { accessToken, user };
  } catch (error) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    throw error;
  }
});

// Handle user logout
export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    await axiosPrivate.get("/auth/logout");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("deviceId");
  } catch (error) {
    toast.error(error?.response?.data?.message || error.message);
    throw error;
  }
});

// Monitor token expiration and refresh if necessary
export const startTokenMonitor = () => (dispatch) => {
  const checkTokenExpiration = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    const decodedToken = jwtDecode(accessToken);
    const expiresAt = decodedToken.exp * 1000;
    const timeRemaining = expiresAt - Date.now();

    if (timeRemaining <= 0) {
      dispatch(logout());
    } else if (timeRemaining < 60000) {
      dispatch(refresh());
    }
  };

  // Immediately check token expiration on dispatch
  checkTokenExpiration();

  // Check every 20 minutes
  setInterval(
    () => {
      dispatch(refresh());
    },
    5 * 60 * 1000
  ); // 5 minutes
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialize(state, action) {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refresh.fulfilled, (state, action) => {
        state.isAuthenticated = !!action.payload.accessToken;
        state.accessToken = action.payload.accessToken;
        state.user = action.payload.user;
      })
      .addCase(refresh.rejected, (state) => {
        state.isAuthenticated = false;
        state.accessToken = "";
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.accessToken = "";
        state.user = null;
        state.isInitialized = false;
      })
      .addCase(logout.rejected, (state) => {
        state.isAuthenticated = false;
        state.accessToken = "";
        state.user = null;
      });
  },
});

export const { initialize } = authSlice.actions;
export default authSlice.reducer;
