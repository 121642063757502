import { createSlice } from "@reduxjs/toolkit";

export const workspace = createSlice({
  name: "workspace",
  initialState: {
    Tasks: [],
    Current_Task: {},
    History_Timestamp: {},
    InitSourceCode: {},
    MemberShip: {},
  },
  reducers: {
    setTasksReducer: (state, action) => {
      state.Tasks = action?.payload;
    },
    setCurrentTaskReducer: (state, action) => {
      state.Current_Task = action?.payload;
    },
    setHistoryTimestampReducer: (state, action) => {
      state.History_Timestamp = action?.payload;
    },
    setInitSourceCodeReducer: (state, action) => {
      state.InitSourceCode = action?.payload;
    },
    setMemberShipReducer: (state, action) => {
      state.MemberShip = action?.payload;
    },
  },
});
export const {
  setTasksReducer,
  setCurrentTaskReducer,
  setHistoryTimestampReducer,
  setInitSourceCodeReducer,
  setMemberShipReducer,
} = workspace.actions;

export default workspace.reducer;
