import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { Scrollbar } from "react-scrollbars-custom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MDBox from "../../../../components/MDBox";
import { CardContent, CardHeader } from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CachedIcon from "@mui/icons-material/Cached";
// import PauseIcon from "@mui/icons-material/Pause";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  // ActiveTaskById,
  // DeactiveTaskById,
  deleteTask,
  getTasks,
  RecheckTaskById,
} from "../../store/action";
import Tooltip from "@mui/material/Tooltip";

// eslint-disable-next-line react/prop-types
function SelectPanel({
  selectById,
  handleParentSelect,
  setEditTask,
  ToogleEditModal,
  handleAlertModal,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuTaskId, setMenuTaskId] = React.useState(null); // Track the task ID for the open menu
  const [checked, setChecked] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const tasks = useSelector((state) => state.workspace.Tasks);

  const handleSelect = (uuid) => {
    handleParentSelect(uuid);
  };

  const handleMenuOpen = (event, uuid) => {
    setAnchorEl(event.currentTarget);
    setMenuTaskId(uuid); // Set the task ID for which the menu is opened
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTaskId(null); // Reset the task ID when closing the menu
  };

  const handleEdit = (task) => {
    ToogleEditModal();
    setEditTask({
      uuid: task.uuid,
      title: task.title,
      url: task.url,
      frequency: task.frequency,
    });
    handleMenuClose(); // Close the menu after edit
  };

  const handleCheck = (uuid) => () => {
    const currentIndex = checked.indexOf(uuid);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(uuid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleRecheck = async (id) => {
    setLoading(true);
    try {
      handleParentSelect(null);
      await dispatch(RecheckTaskById(id));
      handleMenuClose(); // Close the menu after recheck
    } catch (error) {
      console.error("Failed to recheck task:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleDeactive = async (uuid) => {
  //   setLoading(true);
  //   try {
  //     handleParentSelect(null);
  //     await dispatch(DeactiveTaskById(uuid));
  //     handleMenuClose(); // Close the menu after deactivating
  //   } catch (error) {
  //     console.error("Failed to deactivate task:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleActive = async (uuid) => {
  //   setLoading(true);
  //   try {
  //     handleParentSelect(null);
  //     await dispatch(ActiveTaskById(uuid));
  //     handleMenuClose(); // Close the menu after activating
  //   } catch (error) {
  //     console.error("Failed to activate task:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async (uuid) => {
    setLoading(true);
    try {
      await dispatch(deleteTask(uuid));
      await dispatch(getTasks());
      handleMenuClose(); // Close the menu after deleting
    } catch (error) {
      console.error("Failed to delete task:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tasks.length === 0) {
      handleParentSelect(null);
    }
  }, [tasks]);

  return (
    <Card sx={{ height: 660 }}>
      <CardHeader title={"Current Files"} />
      <MDBox sx={{ mx: 1, height: 5, mb: 2, display: "flex" }} hidden={checked.length === 0}>
        <Stack direction={{ xs: "row" }} spacing={1}>
          {/*<Tooltip title={"Active Jobs"}>*/}
          {/*  <PlayArrowIcon*/}
          {/*    fontSize="medium"*/}
          {/*    sx={{ cursor: "pointer", display: checked.length === 0 ? "none" : "null" }}*/}
          {/*    color="action"*/}
          {/*    onClick={() => {*/}
          {/*      checked.forEach((uuid) => {*/}
          {/*        handleActive(uuid);*/}
          {/*      });*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Tooltip>*/}
          {/*<Tooltip title={"Deactive Jobs"}>*/}
          {/*  <PauseIcon*/}
          {/*    fontSize="medium"*/}
          {/*    color="action"*/}
          {/*    sx={{ cursor: "pointer", display: checked.length === 0 ? "none" : "null" }}*/}
          {/*    onClick={() => {*/}
          {/*      checked.forEach((uuid) => {*/}
          {/*        handleDeactive(uuid);*/}
          {/*      });*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Tooltip>*/}
          <Tooltip title={"Recheck Jobs"}>
            <CachedIcon
              fontSize="medium"
              color="action"
              sx={{ cursor: "pointer", display: checked.length === 0 ? "none" : "null" }}
              onClick={() => {
                checked.forEach((uuid) => {
                  handleRecheck(uuid);
                });
              }}
            />
          </Tooltip>
          <Tooltip title={"Delete Jobs"}>
            <DeleteSweepIcon
              fontSize="medium"
              color="action"
              sx={{ cursor: "pointer", display: checked.length === 0 ? "none" : "null" }}
              onClick={() => {
                checked.forEach((uuid) => {
                  handleDelete(uuid);
                });
              }}
            />
          </Tooltip>
        </Stack>
      </MDBox>
      <Divider />
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="secondary" />
        </MDBox>
      ) : (
        <Scrollbar>
          <CardContent sx={{ px: 0.5 }}>
            {/* Active Jobs */}
            <MDTypography sx={{ mx: 1, mb: 2 }}>
              {tasks && tasks.filter((task) => task.paused === false).length > 0
                ? `Active Jobs (${tasks && tasks.filter((task) => task.paused === false).length})`
                : null}
            </MDTypography>
            <List sx={{ width: "100%" }}>
              {tasks &&
                tasks
                  .filter((task) => task.paused === false)
                  .map((task) => (
                    <MDBox
                      key={task.uuid}
                      sx={{ mx: 1, mb: 1, mt: 1, cursor: "pointer" }}
                      bgColor={selectById === task.uuid ? "light" : "default"}
                      borderRadius="lg"
                    >
                      <ListItem
                        secondaryAction={
                          <>
                            <IconButton
                              edge="end"
                              sx={{ mx: 2, zIndex: 1301 }}
                              onClick={(event) => handleMenuOpen(event, task.uuid)}
                            >
                              <MoreHorizIcon fontSize="medium" />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl) && menuTaskId === task.uuid} // Only open the menu for the selected task
                              onClose={handleMenuClose}
                              sx={{ cursor: "pointer" }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleEdit(task);
                                  handleMenuClose();
                                }}
                              >
                                <EditNoteIcon sx={{ mr: 0.5 }} fontSize="small" />
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleAlertModal(task.uuid);
                                  handleMenuClose();
                                }}
                              >
                                <DeleteSweepIcon sx={{ mr: 0.5 }} fontSize="small" />
                                Delete
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleRecheck(task.uuid);
                                  handleMenuClose();
                                }}
                              >
                                <CachedIcon sx={{ mr: 0.5 }} fontSize="small" />
                                Recheck
                              </MenuItem>
                              {/*<MenuItem*/}
                              {/*  onClick={() => {*/}
                              {/*    handleDeactive(task.uuid);*/}
                              {/*    handleMenuClose();*/}
                              {/*  }}*/}
                              {/*>*/}
                              {/*  <PauseIcon sx={{ mr: 0.5 }} fontSize="small" />*/}
                              {/*  Deactivate*/}
                              {/*</MenuItem>*/}
                            </Menu>
                          </>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          sx={{ borderRadius: "0.5rem" }}
                          role={undefined}
                          dense
                          onClick={() => handleSelect(task.uuid)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(task.uuid) !== -1}
                              tabIndex={-1}
                              onClick={handleCheck(task.uuid)}
                            />
                          </ListItemIcon>
                          <MDBox>
                            <MDTypography>{task.title}</MDTypography>
                            <Link
                              href={task ? task.url : "#"}
                              textAlign={"center"}
                              color={"primary"}
                              alignItems={"center"}
                              display={"flex"}
                              target={"_blank"}
                              sx={{ fontSize: "small" }}
                            >
                              {task.url}
                              <Icon>launch</Icon>
                            </Link>
                          </MDBox>
                        </ListItemButton>
                      </ListItem>
                    </MDBox>
                  ))}
            </List>

            {/* Inactive Jobs */}
            <MDTypography sx={{ mx: 1, mb: 2 }}>
              {tasks && tasks.filter((task) => task.paused === true).length > 0
                ? `Inactive Jobs (${tasks.filter((task) => task.paused === true).length})`
                : null}
            </MDTypography>
            <List sx={{ width: "100%" }}>
              {tasks &&
                tasks
                  .filter((task) => task.paused === true)
                  .map((task) => (
                    <MDBox
                      key={task.uuid}
                      sx={{ mx: 1, mb: 1, mt: 1, cursor: "pointer" }}
                      borderRadius="lg"
                    >
                      <ListItem
                        secondaryAction={
                          <>
                            <IconButton
                              edge="end"
                              sx={{ mx: 2, zIndex: 1301 }}
                              onClick={(event) => handleMenuOpen(event, task.uuid)}
                            >
                              <MoreHorizIcon fontSize="medium" />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl) && menuTaskId === task.uuid} // Only open the menu for the selected task
                              onClose={handleMenuClose}
                              sx={{ cursor: "pointer" }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleAlertModal(task.uuid);
                                  handleMenuClose();
                                }}
                              >
                                <DeleteSweepIcon sx={{ mr: 0.5 }} fontSize="small" />
                                Delete
                              </MenuItem>
                              {/*<MenuItem*/}
                              {/*  onClick={() => {*/}
                              {/*    handleActive(task.uuid);*/}
                              {/*    handleMenuClose();*/}
                              {/*  }}*/}
                              {/*>*/}
                              {/*  <PlayArrowIcon sx={{ mr: 0.5 }} fontSize="small" />*/}
                              {/*  Make Active*/}
                              {/*</MenuItem>*/}
                            </Menu>
                          </>
                        }
                        disablePadding
                      >
                        <ListItemButton sx={{ borderRadius: "0.5rem" }} role={undefined} dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(task.uuid) !== -1}
                              tabIndex={-1}
                              defaultChecked
                              onClick={handleCheck(task.uuid)}
                            />
                          </ListItemIcon>
                          <MDBox>
                            <MDTypography>{task.title}</MDTypography>
                            <Link
                              href={task ? task.url : "#"}
                              textAlign={"center"}
                              color={"primary"}
                              alignItems={"center"}
                              display={"flex"}
                              target={"_blank"}
                              sx={{ fontSize: "small" }}
                            >
                              {task.url}
                              <Icon>launch</Icon>
                            </Link>
                          </MDBox>
                        </ListItemButton>
                      </ListItem>
                    </MDBox>
                  ))}
            </List>
          </CardContent>
        </Scrollbar>
      )}
    </Card>
  );
}

export default SelectPanel;
