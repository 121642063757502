// @mui material components
import { Card, CardContent } from "@mui/material";
import Badge from "@mui/material/Badge";
import MDTypography from "../../../../../../components/MDTypography";
import MDBox from "../../../../../../components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../../../components/MDButton";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

// import Icon from "@mui/material/Icon";

function DefaultPriceCard(props) {
  const { badge, price, specifications, action, setselectedPriceOption, loading } = props;
  const [active, setActive] = useState(false);
  return (
    <Card>
      <MDBox sx={{ display: "flex", justifyContent: "center" }}>
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          variant="string"
          badgeContent={
            <MDTypography variant="button" sx={{ m: 3 }}>
              {badge.label}
            </MDTypography>
          }
          color={active ? "success" : badge.color}
        />
      </MDBox>
      <CardContent sx={{ minHeight: "420px", display: "flex", flexDirection: "column" }}>
        <MDBox color={"active"} sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={<MDTypography variant="h5">{price.currency}</MDTypography>}
            sx={{ display: "flex", alignItems: "end" }}
          >
            <MDTypography variant="h2">{price.value}</MDTypography>
            <MDTypography variant="h5">{`/${price.type}`}</MDTypography>
          </Badge>
        </MDBox>
        <MDBox sx={{ mt: 5 }}>
          {specifications &&
            specifications.map((specification, key) => {
              return (
                <MDBox key={key} sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                  <MDBox sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    {specification.includes ? (
                      <Icon fontSize="small" color="success">
                        check_icon
                      </Icon>
                    ) : (
                      <Icon fontSize="small" color="secondary">
                        horizontal_rule_icon
                      </Icon>
                    )}
                  </MDBox>
                  <MDTypography>{specification.label}</MDTypography>
                </MDBox>
              );
            })}
        </MDBox>
        <MDButton
          disabled={action.disabled}
          variant={active ? "contained" : "outlined"}
          fullWidth
          sx={{ mt: "auto" }}
          color={active ? "success" : action.color}
          onClick={() =>
            setselectedPriceOption({
              type: badge.label,
              mode: "payment",
              currency: "usd",
              amount: price.value,
              month: price.type === "mo" ? 1 : 12,
            })
          }
          onMouseEnter={() => {
            setActive(true);
          }}
          onMouseLeave={() => {
            setActive(false);
          }}
        >
          {loading && <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />}
          <MDTypography>{action.label}</MDTypography>
        </MDButton>
      </CardContent>
    </Card>
  );
}

export default DefaultPriceCard;
