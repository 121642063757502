// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "../PublicPageLayout";

// LogoImage imports
import brandWhite from "../../assets/images/logos/logowhite.svg";
import brandDark from "../../assets/images/logos/logodark.svg";
// import Logo from "../../assets/images/logos/leapARMM_logo.png";

// Authentication pages components
import { useMaterialUIController } from "../../context";

function CoverLayout({ image, children }) {
  const [controller] = useMaterialUIController();

  const { darkMode } = controller;

  return (
    <PageLayout>
      <DefaultNavbar brand={!darkMode ? brandDark : brandWhite} brandName="LeapARMM" />
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        {children}
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
CoverLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
