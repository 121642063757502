import * as React from "react";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { Scrollbar } from "react-scrollbars-custom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import ListItem from "@mui/material/ListItem";
import {
  CardContent,
  CardHeader,
  CircularProgress,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Icon,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "../../../../context";
import {
  handleUpdateTaskById,
  setchangeDetectionByTimestamp,
  setInitSourceCode,
  getTaskById,
} from "../../store/action";
import prettyMilliseconds from "pretty-ms";

function DetailsPanel({ selectById, setShowHistoryModal, showHistoryModal }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const currentTask = useSelector((state) => state.workspace.Current_Task);
  const [Changeloading, setChangeloading] = useState(false); // Add loading state
  const [CurrentTaskloading, setCurrentTaskloading] = useState(false); // Add loading state
  const [curHistory, setCurHistory] = useState("");
  const frequencies = ["every 2 mins", "every 5 mins", "every 30 mins", "every day"];
  const { darkMode } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHistory = async (timestamp) => {
    setChangeloading(true); // Set loading to true before fetching
    setCurHistory(timestamp);
    try {
      await dispatch(setInitSourceCode(currentTask));
      await dispatch(setchangeDetectionByTimestamp(currentTask, timestamp));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowHistoryModal(!showHistoryModal);
      setChangeloading(false); // Set loading to false after fetching is done
    }
  };

  const handleFrequency = async (value) => {
    setCurrentTaskloading(true);
    try {
      const updatedTask = {
        ...currentTask,
        frequency: value,
      };

      await dispatch(
        handleUpdateTaskById(currentTask.uuid, {
          title: updatedTask.title,
          url: updatedTask.url,
          frequency: updatedTask.frequency,
        })
      );
      await dispatch(getTaskById(selectById));
    } catch (error) {
      console.error("Error edit data:", error);
    } finally {
      setCurrentTaskloading(false);
      setAnchorEl(null);
    }
  };

  const nextCheck = () => {
    // Ensure that currentTask and its properties are defined and valid numbers
    const lastChecked = currentTask?.last_code_checked ? currentTask.last_code_checked : null;
    const timeBetweenCheck = currentTask?.frequency
      ? convertFrequencyToMs(currentTask.frequency)
      : null;

    if (lastChecked === null || timeBetweenCheck === null) {
      return "No data available"; // Handle cases where data is missing
    }

    const time = lastChecked + timeBetweenCheck;
    const nexttime = time - Date.now();

    if (!isFinite(nexttime) || nexttime <= 0) {
      return "Just Now"; // Handle invalid or negative time differences
    } else {
      return prettyMilliseconds(nexttime, { verbose: true });
    }
  };

  const convertFrequencyToMs = (frequency) => {
    switch (frequency) {
      case "every 2 mins":
        return 2 * 60 * 1000; // 2 minutes in milliseconds
      case "every 5 mins":
        return 5 * 60 * 1000; // 5 minutes in milliseconds
      case "every 30 mins":
        return 30 * 60 * 1000; // 30 minutes in milliseconds
      case "every day":
        return 24 * 60 * 60 * 1000; // 1 day in milliseconds
      default:
        return null; // Return null if frequency is invalid
    }
  };

  useEffect(() => {
    const fetchTaskData = async () => {
      if (selectById) {
        setCurrentTaskloading(true); // Set loading to true before fetching
        try {
          await dispatch(getTaskById(selectById));
          nextCheck(currentTask.last_code_checked);
        } catch (error) {
          console.error("Error fetching task data:", error);
        } finally {
          setCurrentTaskloading(false); // Set loading to false after fetching is done
        }
      }
    };

    fetchTaskData();
  }, [selectById]);

  return (
    <Card sx={{ height: 660 }}>
      <CardHeader title={selectById ? currentTask?.title : null} />
      {CurrentTaskloading ? (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100%", width: "100%" }}
        >
          <CircularProgress color="secondary" />
        </MDBox>
      ) : (
        <>
          <MDBox sx={{ mx: 2, mb: 1 }} hidden={!selectById}>
            <Grid container spacing={3} mb={2}>
              <Grid item lg={12}>
                <MDBox sx={{ mt: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item lg={7} sx={{ display: "flex", alignItems: "center" }}>
                      <MDTypography variant="button" fontWeight="bold">
                        Checks
                      </MDTypography>
                    </Grid>
                    <Grid item lg={5} sx={{ display: "flex", alignItems: "center" }}>
                      <MDTypography variant="button" fontWeight="bold" sx={{ mr: 1 }}>
                        Frequency
                      </MDTypography>
                      <MDButton
                        variant="outlined"
                        color={darkMode ? "light" : "dark"}
                        onClick={handleClick}
                        // sx={{ justifyContent: "space-between" }}
                      >
                        {currentTask?.frequency} <Icon>expand_more</Icon>
                      </MDButton>
                      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        {frequencies &&
                          frequencies.map((val, key) => {
                            return (
                              <MenuItem
                                key={key}
                                onClick={() => {
                                  handleFrequency(val);
                                }}
                              >
                                {val}
                              </MenuItem>
                            );
                          })}
                      </Menu>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                  <Grid container spacing={1}>
                    <Grid item lg={7} md={12}>
                      <MDBox sx={{ display: "flex", alignItems: "center" }} color={"primary"}>
                        <Icon sx={{ mr: 1 }}>access_time</Icon>
                        <MDTypography
                          variant="caption"
                          color={"primary"}
                          onMouseOver={(eve) => {
                            eve.target.textContent =
                              currentTask?.history_code_n > 0
                                ? new Date(currentTask.last_code_changed).toLocaleString()
                                : "no changed detection";
                          }}
                          onMouseOut={(eve) => {
                            eve.target.textContent =
                              currentTask?.history_code_n > 0
                                ? `${prettyMilliseconds(
                                    new Date() - new Date(currentTask?.last_code_changed),
                                    { verbose: true }
                                  )} ago`
                                : `no changed detection`;
                          }}
                          sx={{ width: "max-content" }}
                        >
                          {currentTask?.history_code_n > 0
                            ? `${prettyMilliseconds(
                                new Date() - new Date(currentTask?.last_code_changed),
                                { verbose: true }
                              )} ago`
                            : `no changed detection`}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item lg={5} md={12}>
                      <MDBox sx={{ display: "flex", alignItems: "center" }} color={"primary"}>
                        <Icon sx={{ mr: 1 }}> outlined_flag_icon </Icon>
                        <MDTypography variant="caption" color={"primary"}>
                          Last Changed
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                  <Grid container spacing={1}>
                    <Grid item lg={7} md={12}>
                      <MDBox sx={{ display: "flex", alignItems: "center" }} color={"info"}>
                        <Icon sx={{ mr: 1 }}>access_time</Icon>
                        <MDTypography variant="caption" color={"info"}>
                          {currentTask?.paused ? "paused" : `in ${nextCheck()}`}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item lg={5} md={12}>
                      <MDBox sx={{ display: "flex", alignItems: "center" }} color={"info"}>
                        <Icon sx={{ mr: 1 }}>cached_icon</Icon>
                        <MDTypography variant="caption" color={"info"}>
                          Next run scheduled
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                  <Grid container spacing={1}>
                    <Grid item lg={7} md={12}>
                      <MDBox sx={{ display: "flex", alignItems: "center" }}>
                        <Icon sx={{ mr: 1 }}>access_time</Icon>
                        <MDTypography
                          variant="caption"
                          onMouseOver={(eve) => {
                            eve.target.textContent = new Date(
                              currentTask?.last_code_checked
                            ).toLocaleString();
                          }}
                          onMouseOut={(eve) => {
                            eve.target.textContent = `${prettyMilliseconds(
                              new Date() - new Date(currentTask?.last_code_checked),
                              { verbose: true }
                            )} ago`;
                          }}
                          sx={{ width: "max-content" }}
                        >
                          {new Date() - new Date(currentTask?.last_code_checked) > 10000
                            ? `${prettyMilliseconds(
                                new Date() - new Date(currentTask?.last_code_checked),
                                { verbose: true }
                              )} ago`
                            : `Just Now`}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item lg={5} md={12}>
                      <MDBox sx={{ display: "flex", alignItems: "center" }} color={"text"}>
                        <Icon sx={{ mr: 1 }}> outlined_flag_icon </Icon>
                        <MDTypography variant="caption" color={"text"}>
                          Last Checked
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
            <MDTypography variant="button" fontWeight="bold">
              Changes detected
            </MDTypography>
          </MDBox>
          <Scrollbar hidden={!selectById}>
            <CardContent sx={{ px: 1 }}>
              <MDBox>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <MDTypography
                  sx={{
                    position: "absolute",
                    top: "50%",
                    textAlign: "center",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  variant="body2"
                  color="text"
                  hidden={currentTask.history_code_n > 0 ? true : false}
                >
                  no changes detected
                </MDTypography>
                <List sx={{ width: "100%" }}>
                  {currentTask.codeChanges &&
                    currentTask?.codeChanges?.map((change, index) => {
                      return (
                        <MDBox
                          key={index}
                          sx={{ mx: 1, mb: 1, mt: 1, cursor: "pointer" }}
                          bgColor={"info"}
                          borderRadius="lg"
                          hidden={currentTask.history_code_n > 0 ? false : true}
                          onClick={() => {
                            handleHistory(change.timestamp);
                          }}
                        >
                          <ListItem disablePadding>
                            <ListItemButton sx={{ borderRadius: "0.5rem" }} role={undefined} dense>
                              <Grid
                                spacing={1}
                                container
                                sx={{
                                  display: "flex",
                                  mb: 1,
                                  mt: 1,
                                  width: "-webkit-fill-available",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid
                                  item
                                  lg={4}
                                  md={12}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Icon sx={{ mr: 1 }}>access_time</Icon>
                                  <MDTypography
                                    variant="caption"
                                    onMouseOver={(eve) => {
                                      eve.target.textContent = new Date(
                                        change.timestamp
                                      ).toLocaleString();
                                    }}
                                    onMouseOut={(eve) => {
                                      eve.target.textContent = `${prettyMilliseconds(
                                        new Date() - new Date(change.timestamp),
                                        { verbose: true }
                                      )} ago`;
                                    }}
                                    sx={{ width: "max-content" }}
                                  >
                                    {`${prettyMilliseconds(
                                      new Date() - new Date(change.timestamp),
                                      {
                                        verbose: true,
                                      }
                                    )} ago`}
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  lg={3}
                                  md={12}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {Changeloading && change.timestamp === curHistory && (
                                    <CircularProgress
                                      size={25}
                                      color="secondary"
                                      sx={{ margin: 0, padding: 0 }}
                                    />
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={12}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <AutoFixHighIcon color={"success"} />
                                  <MDTypography variant="caption" sx={{ ml: 1 }}>
                                    {"view relevant changes"}
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </ListItemButton>
                          </ListItem>
                        </MDBox>
                      );
                    })}
                </List>
              </MDBox>
            </CardContent>
          </Scrollbar>
        </>
      )}
      <MDBox
        sx={{
          display: "flex",
          width: "-webkit-fill-available",
          position: "absolute",
          justifyContent: "center",
          bottom: "50%",
        }}
      >
        <MDTypography sx={{ mt: 25, textAlign: "center" }} hidden={!!selectById}>
          Select a job to view changes
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default DetailsPanel;
