import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
// import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "../PublicPageLayout";

// LogoImage imports
// import brandWhite from "../../assets/images/logos/logowhite.svg";
// import brandDark from "../../assets/images/logos/logodark.svg";
// import Logo from "../../assets/images/logos/leapARMM_logo.png";

// Authentication pages components
import Footer from "../../pages/authentication/view/components/Footer";
// import { useMaterialUIController } from "../../context";

const links = [
  // { href: "/landing/aboutus", name: "About Us" },
  // { href: "/landing/aboutus", name: "Contact US" },
];

function BasicLayout({ image, children }) {
  // const [controller] = useMaterialUIController();

  // const { darkMode } = controller;
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    const useUrl = "/dashboard";
    return <Navigate to={useUrl} />;
  }
  return (
    <PageLayout>
      {/*<DefaultNavbar brand={!darkMode ? Logo : Logo} brandName="LeapARMM" />*/}
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox
        px={1}
        width="100%"
        minHeight="100vh"
        mx="auto"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {children}
      </MDBox>
      <Footer company={{ href: "/dashboard", name: "Quantum Squint" }} links={links} />
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
