import * as reducer from "./reducer";
import * as api from "./api";

export const fetchUIMonitorTaskById = (uuid) => async (dispatch) => {
  try {
    const { data } = await api.fetchUIMonitorTaskById(uuid);
    dispatch(reducer.setVisualScreenTaskReducer(data.data));
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};

export const fetchScreens = (uuid, timestamp) => async (dispatch) => {
  try {
    const { data } = await api.fetchScreens(uuid, timestamp);
    dispatch(reducer.setPreviousScreenReducer(data.data.previousScreenshot));
    dispatch(
      reducer.setScreenByTimestampReducer({
        timestamp: timestamp,
        screen: data.data.originScreenshot,
      })
    );
    dispatch(reducer.setDiffScreenReducer(data.data.diffScreenshot));
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};

export const RecheckTaskById = (uuid) => async (dispatch) => {
  try {
    await api.RecheckTaskById(uuid);
    await dispatch(fetchUIMonitorTaskById(uuid));
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
