import { useAxiosPrivate } from "../../../hooks/useAxiosPrivate";
import { ENDPOINTS } from "../../../lib/endpoints";

const apiPrivate = useAxiosPrivate();

export async function fetchUIMonitorTaskById(Task_Id) {
  return await apiPrivate.get(`${ENDPOINTS.Task}/${Task_Id}`);
}

export async function fetchScreens(Task_Id, timestamp) {
  return await apiPrivate.get(`${ENDPOINTS.Task}/${Task_Id}/screenshot/fethch/${timestamp}`);
}

export async function RecheckTaskById(Task_Id) {
  return await apiPrivate.get(`${ENDPOINTS.Task}/${Task_Id}/screenshot/recheck`);
}
