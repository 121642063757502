import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useMaterialUIController } from "../../../../context";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Unstable_Grid2";

import { initScreenReducer } from "../../store/reducer";
import { fetchScreens } from "../../store/action";

function CompareModal({ showHistoryModal, setShowHistoryModal }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const currentTask = useSelector((state) => state.screen_monitor_task.CurrentTask);
  const previousScreen = useSelector((state) => state.screen_monitor_task.previousScreen);
  const Screen_Timestamp = useSelector(
    (state) => state.screen_monitor_task.originScreenByTimestamp.screen
  );
  const current_timestamp = useSelector(
    (state) => state.screen_monitor_task.originScreenByTimestamp.timestamp
  );
  const diffScreen = useSelector((state) => state.screen_monitor_task.diffScreen);

  const [loading, setLoading] = useState(false); // Add loading state

  const handleClose = () => {
    setShowHistoryModal(false);
    dispatch(initScreenReducer);
  };

  const handlePrevious = async () => {
    try {
      setLoading(true); // Start loading
      const previous_change = currentTask.changes
        .filter((change) => change.timestamp < current_timestamp)
        .reverse()[0];
      if (previous_change) {
        await dispatch(fetchScreens(currentTask.uuid, previous_change.timestamp));
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleNext = async () => {
    try {
      setLoading(true); // Start loading
      const next_change = currentTask.changes.filter(
        (change) => change.timestamp > current_timestamp
      )[0];
      if (next_change) {
        await dispatch(fetchScreens(currentTask.uuid, next_change.timestamp));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const nextCheckAction = () => {
    const next_change = currentTask?.changes?.filter(
      (change) => change.timestamp > current_timestamp
    )[0];
    return !!next_change;
  };

  const previousCheckAction = () => {
    const previous_change = currentTask?.changes
      ?.filter((change) => change.timestamp < current_timestamp)
      .reverse()[0];
    return !!previous_change;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showHistoryModal}
      fullWidth
      maxWidth={"xxl"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#2a2b2f" : "#EBEFF4",
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between" }}>
        <MDTypography>{currentTask.title}</MDTypography>
        <MDBox sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={"Previous changes"}>
            <IconButton
              aria-label="previous"
              disabled={previousCheckAction() !== true}
              onClick={handlePrevious}
            >
              <SkipPreviousIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Next changes"}>
            <IconButton
              aria-label="next"
              disabled={nextCheckAction() !== true}
              onClick={handleNext}
            >
              <SkipNextIcon />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Tooltip title={"Visit Site"}>
            <Link href={currentTask.url} target={"_blank"}>
              <IconButton aria-label="visit site">
                <LanguageIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <MDBox display="flex" sx={{ position: "relative", height: "500px" }}>
          <MDBox sx={{ flex: 1, overflowY: "auto" }}>
            {loading && (
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  position: "absolute",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "rgba(72,74,77,0.39)", // Slight transparency to indicate loading
                }}
              >
                <CircularProgress color={"secondary"} />
              </MDBox>
            )}
            {/* Show diff of screenshots */}
            <Grid container spacing={2}>
              <Grid item lg={4} md={12} sx={{ textAlign: "center" }}>
                <MDTypography variant="h6" sx={{ mb: 1 }}>
                  <strong>Baseline Screenshot</strong>
                </MDTypography>
                <img
                  src={previousScreen}
                  alt="Previous Screenshot"
                  style={{
                    width: "100%", // Ensure the image takes up the full width
                    objectFit: "contain", // Ensure the image scales properly without distortion
                  }}
                />
              </Grid>
              <Grid item lg={4} md={12} sx={{ textAlign: "center" }}>
                <MDTypography variant="h6" sx={{ mb: 1 }}>
                  <strong>Changed Screenshot</strong>
                </MDTypography>
                <img
                  src={Screen_Timestamp}
                  alt="Current Screenshot"
                  style={{
                    width: "100%", // Ensure the image takes up the full width
                    objectFit: "contain", // Ensure the image scales properly without distortion
                  }}
                />
              </Grid>
              <Grid item lg={4} md={12} sx={{ textAlign: "center" }}>
                <MDTypography variant="h6" sx={{ mb: 1 }}>
                  <strong>Detected Differences</strong>
                </MDTypography>
                <img
                  src={diffScreen}
                  alt="Current Screenshot"
                  style={{
                    width: "100%", // Ensure the image takes up the full width
                    objectFit: "contain", // Ensure the image scales properly without distortion
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}

export default CompareModal;
