import * as api from "./api";
import * as reducer from "./reducer";
import { toast } from "react-toastify";

export const getTasks = () => async (dispatch) => {
  try {
    const { data } = await api.getTasks();
    dispatch(reducer.setTasksReducer(data.data));
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const getMembership = () => async (dispatch) => {
  try {
    const { data } = await api.getMembersip();
    dispatch(reducer.setMemberShipReducer(data));
  } catch (error) {
    dispatch(reducer.setMemberShipReducer({}));
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const getTaskById = (uuid) => async (dispatch) => {
  try {
    const { data } = await api.getTaskById(uuid);
    await dispatch(reducer.setCurrentTaskReducer(data.data));
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const RecheckTaskById = (uuid) => async (dispatch) => {
  try {
    await api.RecheckTaskById(uuid);
    const { data } = await api.getTaskById(uuid);
    await dispatch(reducer.setCurrentTaskReducer(data));
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const DeactiveTaskById = (uuid) => async (dispatch) => {
  try {
    await api.DeactiveTaskById(uuid);
    await dispatch(getTasks());
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const ActiveTaskById = (uuid) => async (dispatch) => {
  try {
    await api.ActiveTaskById(uuid);
    await dispatch(getTasks());
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const createTask = (payload) => async (dispatch) => {
  try {
    const { data } = await api.createTask(payload);
    if (data?.success === true) {
      await dispatch(getTasks());
      toast.success("The task was created successfully.");
    } else {
      toast.error("An error occurred while attempting to create the item.");
    }
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const handleUpdateTaskById = (Task_Id, payload) => async (dispatch) => {
  try {
    const { data } = await api.handleUpdateTaskById(Task_Id, payload);
    if (data.success === true) {
      dispatch(getTasks());
    }
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const deleteTask = (Task_Id) => async (dispatch) => {
  try {
    const { data } = await api.deleteTask(Task_Id);
    if (data.success === true) {
      await dispatch(getTasks());
      toast.success("The task has been successfully deleted.");
    } else {
      toast.error("Oops! Something went wrong and we couldn't delete the item.");
    }
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data);
    } else {
      console.log(error?.message);
    }
  }
};
export const setchangeDetectionByTimestamp = (Task, Timestamp) => async (dispatch) => {
  try {
    const matchedChange = Task.codeChanges.find((change) => change.timestamp === Timestamp);
    if (!matchedChange) {
      throw new Error("Code change not found for the given timestamp.");
    }
    await dispatch(
      reducer.setHistoryTimestampReducer({
        timestamp: matchedChange.timestamp,
        source: matchedChange.sourceCode,
      })
    );
  } catch (error) {
    toast.error(`${error.message}`);
  }
};
export const setInitSourceCode = (Task) => async (dispatch) => {
  try {
    await dispatch(reducer.setInitSourceCodeReducer({ source: Task.initialSourceCode.source }));
  } catch (error) {
    toast.error(`${error.message}`);
  }
};
