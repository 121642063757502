import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["auth/initialize", "auth/logout"],
        ignoredPaths: ["auth.isAuthenticated", "auth.accessToken"],
      },
    }),
});

export { store };
