import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "../../components/MDBox";
import { useMaterialUIController, setMiniSidenav, setLayout } from "context";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import Sidenav from "../../examples/Sidenav";
import brandWhite from "../../assets/images/logos/logowhite.svg";
import brandDark from "../../assets/images/logos/logodark.svg";
import Configurator from "../../examples/Configurator";
import privateroutes from "../../routes/privateroutes";

const links = [
  // { href: "/landing/aboutus", name: "About Us" },
  // { href: "/landing/contactus", name: "Contact Us" },
];

function DashboardLayout({ children }) {
  // const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [controller, dispatchController] = useMaterialUIController();
  const { miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const { pathname } = useLocation();

  // Open sidenav when mouse enters mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatchController, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leaves mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatchController, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    setLayout(dispatchController, "dashboard");
  }, [dispatchController, pathname]);

  useEffect(() => {
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
    }
  }, [isAuthenticated, pathname, requestedLocation]);

  if (!isAuthenticated) {
    return <Navigate to="/authentication/sign-in" state={{ from: requestedLocation }} replace />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <MDBox sx={{ position: "relative", minHeight: "100vh" }}>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="LeapARMM"
            routes={privateroutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator brandName={"LeapARMM"} brand={!darkMode ? brandDark : brandWhite} />
        </>
      )}
      {layout === "vr" && <Configurator brandName={"LeapARMM"} />}
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          pb: 10,
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <DashboardNavbar />
        {children}
      </MDBox>
      <Footer company={{ href: "/dashboard", name: "Quantum Squint" }} links={links} />
    </MDBox>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
