import { v4 as uuidv4 } from "uuid";
import { ENDPOINTS } from "../../../lib/endpoints";

import { useAxiosPrivate } from "../../../hooks/useAxiosPrivate";
// import { api } from "utils/axios";

import { toast } from "react-toastify";

const apiPrivate = useAxiosPrivate();

export async function getTasks() {
  return await apiPrivate.get(ENDPOINTS.Task);
}

export async function getMembersip() {
  try {
    return await apiPrivate.get(`${ENDPOINTS.Membership}/status`);
  } catch (error) {
    if (error.response) {
      console.log(error?.response?.data?.message);
    } else {
      console.log(error?.message);
    }
  }
}

export async function getTaskById(Task_Id) {
  return await apiPrivate.get(`${ENDPOINTS.Task}/${Task_Id}`);
}
export async function RecheckTaskById(Task_Id) {
  return await apiPrivate.get(`${ENDPOINTS.Task}/${Task_Id}/code/recheck`);
}
export async function DeactiveTaskById(Task_Id) {
  try {
    await apiPrivate.put(`${ENDPOINTS.Task}/${Task_Id}`, { paused: true });
  } catch (error) {
    if (error.response) {
      toast.error(error?.response?.data);
    } else {
      toast.error(error?.message);
    }
  }
}
export async function ActiveTaskById(Task_Id) {
  try {
    await apiPrivate.put(`${ENDPOINTS.Task}/${Task_Id}`, { paused: false });
  } catch (error) {
    if (error.response) {
      toast.error(error?.response?.data);
    } else {
      toast.error(error?.message);
    }
  }
}
export const createTask = async (payload) => {
  try {
    payload.uuid = uuidv4();
    return await apiPrivate.post(ENDPOINTS.Task, payload);
  } catch (error) {
    if (error.response) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
  }
};
export async function handleUpdateTaskById(Task_Id, data) {
  try {
    return await apiPrivate.put(`${ENDPOINTS.Task}/${Task_Id}`, data);
  } catch (error) {
    if (error.response) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
  }
}
export async function deleteTask(Task_Id) {
  try {
    return await apiPrivate.delete(`${ENDPOINTS.Task}/${Task_Id}`);
  } catch (error) {
    if (error.response) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
  }
}
