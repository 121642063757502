// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "../../../../../assets/theme/base/typography";

function Footer({ light, links, company }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      // eslint-disable-next-line react/jsx-key
      <MDBox component="li" px={2} key={link.name} lineHeight={1}>
        <Link href={link.href}>
          <MDTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox width="100%" position={{ xs: "relative", lg: "absolute" }} bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="center"
          alignItems="center"
          px={1.5}
        >
          {/*<MDBox*/}
          {/*  display="flex"*/}
          {/*  justifyContent="center"*/}
          {/*  alignItems="center"*/}
          {/*  flexWrap="wrap"*/}
          {/*  color={light ? "white" : "text"}*/}
          {/*  fontSize={size.sm}*/}
          {/*>*/}
          {/*  &copy; {new Date().getFullYear()}*/}
          {/*  <Link href={href}>*/}
          {/*    <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>*/}
          {/*      &nbsp;{name} LLC.&nbsp;*/}
          {/*    </MDTypography>*/}
          {/*  </Link>*/}
          {/*</MDBox>*/}
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            {renderLinks()}
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()}
            <Link href={href}>
              <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                &nbsp;{name} LLC.&nbsp;
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
