import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useMaterialUIController } from "../../../../context";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import * as reducer from "../../store/reducer";
import DownloadIcon from "@mui/icons-material/Download";
import { DiffEditor } from "@monaco-editor/react"; // Import DiffEditor

function CompareModal({ showHistoryModal, setShowHistoryModal }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const InitSourceCode = useSelector((state) => state.workspace.InitSourceCode);
  const currentTask = useSelector((state) => state.workspace.Current_Task);
  const History_Timestamp = useSelector((state) => state.workspace.History_Timestamp);
  const current_timestamp = useSelector((state) => state.workspace.History_Timestamp.timestamp);

  const handleClose = () => {
    setShowHistoryModal(false);
    dispatch(reducer.setInitSourceCodeReducer({}));
    dispatch(reducer.setHistoryTimestampReducer({}));
  };

  const handlePrevious = async () => {
    try {
      const previous_change = currentTask.codeChanges
        .filter((change) => change.timestamp < current_timestamp)
        .reverse()[0];
      if (previous_change) {
        await dispatch(
          reducer.setHistoryTimestampReducer({
            timestamp: previous_change.timestamp,
            source: previous_change.sourceCode,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = async () => {
    try {
      const next_change = currentTask.codeChanges.filter(
        (change) => change.timestamp > current_timestamp
      )[0];
      if (next_change) {
        await dispatch(
          reducer.setHistoryTimestampReducer({
            timestamp: next_change.timestamp,
            source: next_change.sourceCode,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextCheckAction = () => {
    const next_change = currentTask?.codeChanges?.filter(
      (change) => change.timestamp > current_timestamp
    )[0];
    return !!next_change;
  };

  const previousCheckAction = () => {
    const previous_change = currentTask?.codeChanges
      ?.filter((change) => change.timestamp < current_timestamp)
      .reverse()[0];
    return !!previous_change;
  };

  const handleDownloadCode = () => {
    const source = InitSourceCode.source;

    if (source) {
      const blob = new Blob([source], { type: "text/html" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${currentTask?.title || "source-code"}.html`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      console.error("No source code available to download");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showHistoryModal}
      fullWidth
      maxWidth={"xxl"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#2a2b2f" : "#EBEFF4",
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between" }}>
        <MDTypography>{currentTask.title}</MDTypography>
        <MDBox sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={"Previous changes"}>
            <IconButton
              aria-label="previous"
              disabled={previousCheckAction() !== true}
              onClick={handlePrevious}
            >
              <SkipPreviousIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Next changes"}>
            <IconButton
              aria-label="next"
              disabled={nextCheckAction() !== true}
              onClick={handleNext}
            >
              <SkipNextIcon />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Tooltip title={"Visit Site"}>
            <Link href={currentTask.url} target={"_blank"}>
              <IconButton aria-label="visit site">
                <LanguageIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MDBox>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "hidden" }}>
        <MDBox sx={{ position: "relative", width: "100%", height: "650px" }}>
          <MDBox
            sx={{
              display: "flex",
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: 1,
              overflowY: "hidden",
            }}
          >
            <MDBox
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: darkMode ? "#2a2b2f" : "#EBEFF4",
              }}
            >
              <MDTypography variant="h6">
                <strong>Baseline Page</strong>
              </MDTypography>
              <Tooltip title={"download sourcecode"}>
                <IconButton aria-label="previous" onClick={handleDownloadCode}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
            <MDBox
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: darkMode ? "#2a2b2f" : "#EBEFF4",
              }}
            >
              <MDTypography variant="h6">
                <strong>Change Detection Page</strong>
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox
            sx={{
              position: "absolute",
              top: "40px",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <DiffEditor
              original={InitSourceCode.source || ""}
              modified={History_Timestamp.source || ""}
              language="html"
              theme={darkMode ? "vs-dark" : "vs-light"}
              options={{
                readOnly: true,
                renderSideBySide: true,
                minimap: { enabled: true },
                automaticLayout: true,
              }}
            />
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}

export default CompareModal;
