// React hooks
import { useState } from "react";
// import { useDispatch } from "react-redux";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../../../LayoutContainers/BasicLayout";

// Images
import bgImage from "../../../../assets/images/bg-sign-in-basic.jpeg";
import { axiosPrivate } from "utils/axios";
import { toast } from "react-toastify";
import brandWhite from "../../../../assets/images/logos/logowhite.svg";
import brandDark from "../../../../assets/images/logos/logodark.svg";
import { useMaterialUIController } from "../../../../context";
import CircularProgress from "@mui/material/CircularProgress";
// import { toast } from "react-toastify";
// import { ENDPOINTS } from "../../../../lib/endpoints";
// import { handleSignup } from "../../../../redux/authentication/action";

const handleSignup = async (userInfo) => {
  return await axiosPrivate.post("/auth/signup", userInfo);
};

function SignUp({ brandName }) {
  // const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [terms, setTerms] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userName: null,
    email: null,
    password: null,
    role: "is_user",
  });

  const handleTermsandCondintion = () => setTerms(!terms);
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await handleSignup(userInfo);
      toast.success(data.message);
    } catch (error) {
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message);
      }
    } finally {
      setLoading(false); // Stop loading after the request completes
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ position: "absolute", top: "20%", width: 350 }}>
        <MDBox borderRadius="lg" mx={5} mt={5} display="flex" sx={{ justifyContent: "center" }}>
          <MDBox display="flex" alignItems="center">
            <MDBox
              component="img"
              src={darkMode ? brandWhite : brandDark}
              alt="Brand"
              width="4rem"
              sx={{ mr: 1 }}
            />
            <MDBox display="flex" sx={{ justifyContent: "center" }}>
              <MDTypography variant="h3" fontWeight="bold" sx={{ width: "max-content" }}>
                {brandName}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          {/*<MDBox sx={{ textAlign: "center" }}>*/}
          {/*  /!*<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>*!/*/}
          {/*  /!*  Join us today*!/*/}
          {/*  /!*</MDTypography>*!/*/}
          {/*  <MDTypography variant="button"> Enter your email and password </MDTypography>*/}
          {/*</MDBox>*/}
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username"
                variant="standard"
                fullWidth
                onChange={(event) => {
                  setUserInfo({ ...userInfo, userName: event.target.value });
                }}
                value={userInfo.userName}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(event) => {
                  setUserInfo({ ...userInfo, email: event.target.value });
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                onChange={(event) => {
                  setUserInfo({ ...userInfo, password: event.target.value });
                }}
                value={userInfo.password}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                defaultChecked
                size="small"
                value={terms}
                onClick={handleTermsandCondintion}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                disabled={terms ? false : true}
                fullWidth
              >
                {loading && <CircularProgress color="light" size={20} sx={{ mr: 1 }} />}
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="success"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
