import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/auth/authSlice";
// import { Navigate } from "react-router-dom";
// import BasicLayout from "../../../../LayoutContainers/BasicLayout";
// import bgImage from "../../../../assets/images/bg-sign-in-basic.jpeg";
// import { Navigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(logout());
  // return <Navigate to={"/dashboard"} />;
  // return <BasicLayout image={bgImage}></BasicLayout>;
};

export default Logout;
