import React from "react";
import { useNavigate } from "react-router-dom";
// import { useSearchParams } from "react-router-dom";
// import { useAxiosPrivate } from "../../../../hooks/useAxiosPrivate";
// MUI components
// import Card from "@mui/material/Card";
// Material Kit 2 React components
import MDBox from "../../../../components/MDBox";
import { CardContent } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
// import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";
import MDButton from "../../../../components/MDButton";
// import success_dark from "../../../../assets/images/subscribe_success_dark.svg";
import success_dark from "../../../../assets/images/subscribe_success_white.svg";
// import brandDark from "../../../../assets/images/subscribe_success_dark.svg";

const Success = () => {
  // const [searchParams] = useSearchParams();
  // const sessionId = searchParams.get("session_id");
  // const apiPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        position: "absolute",
        top: "39%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <MDBox
        sx={{
          width: "100%",
        }}
      >
        <CardContent>
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <MDBox component="img" src={success_dark} width="9rem" sx={{ mr: 1 }} />
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <MDTypography variant="h2">Subscription Successful!</MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography color="secondary" variant="body2">
              Thank you for your business with us! you can immediately start now.
            </MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <MDButton onClick={() => navigate("/dashboard")}>Go Home</MDButton>
          </MDBox>
        </CardContent>
      </MDBox>
    </Container>
  );
};

export default Success;
