export const ENDPOINTS = {
  // Auth
  // SEND_PASS_RESET_OTP: "auth/send-reset-pass-otp",
  // RESET_PASS: "/auth/reset-password",

  // get user
  GET_USER: "/user",
  GET_USERS: "/user/get_users",
  GET_USERS_WITH_COMMUNITY: "/user/community",
  PAYMENT_CREATE_INTENT: "/payment/create-payment-intent",
  PAYMENT: "/payment",

  // ** WorkSpace Tasks
  Api_Task: "/watch",
  Task: "/task",
  Membership: "membership",
  Change_Detection: "/change-detection",
};
