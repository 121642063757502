import { useEffect } from "react";
import { useDispatch } from "react-redux";
// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import theme from "assets/theme";
// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
// Material Dashboard 2 React routes
import routes from "./routes/routes";
import PaymentPage from "./pages/payment/view";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { initializeAuth, startTokenMonitor } from "./redux/auth/authSlice";

export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    dispatch(initializeAuth()).then(() => {
      dispatch(startTokenMonitor());
    });
  }, [dispatch]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/payment/*" element={<PaymentPage />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
