import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import Transaction from "../Transaction";

function Transactions({ darkMode }) {
  const transactions = useSelector((state) => state.payment.TransactionsHistory);

  if (!transactions || transactions.length === 0) {
    return (
      <MDBox
        sx={{
          display: "flex",
          height: "75%",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <MDTypography variant="body2" color="primary">
          No transactions available.
        </MDTypography>
      </MDBox>
    );
  }

  const now = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(now.getMonth() - 1);

  const latestTransactions = transactions
    .filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      return transactionDate > oneMonthAgo;
    })
    .toReversed();

  const oldTransactions = transactions
    .filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      return transactionDate <= oneMonthAgo;
    })
    .toReversed();

  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Your Transactions
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="primary" fontSize="small">
              date_range
            </Icon>
          </MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            {format(oneMonthAgo, "MMM d, yyyy")} - {format(now, "MMM d, yyyy")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        {latestTransactions.length > 0 && (
          <>
            <MDBox display="flex" alignItems="center" mb={2}>
              <Icon color="primary" fontSize="small">
                calendar_today
              </Icon>
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
                ml={1}
              >
                Transactions This Month
              </MDTypography>
            </MDBox>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {latestTransactions.map((transaction) => (
                <MDBox key={transaction._id}>
                  <Transaction
                    transactionId={transaction._id}
                    darkMode={darkMode}
                    color={
                      transaction.status === "succeeded"
                        ? "success"
                        : transaction.status === "pending"
                          ? "info"
                          : "error"
                    }
                    icon="expand_more"
                    name={transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                    description={format(new Date(transaction.createdAt), "MMM d, yyyy, h:mm a")}
                    value={`-$ ${parseFloat((transaction.amount / 100).toFixed(2))}`}
                    successStatus={transaction.status}
                    paidDate={transaction.createdAt}
                    paymentType={transaction.payment_method_details?.type || "Unknown"} // Safely access the type
                  />
                </MDBox>
              ))}
            </MDBox>
          </>
        )}

        {oldTransactions.length > 0 && (
          <>
            <MDBox display="flex" alignItems="center" mt={4} mb={2}>
              <Icon color="primary" fontSize="small">
                calendar_today
              </Icon>
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
                ml={1}
              >
                Longer-Term Transactions
              </MDTypography>
            </MDBox>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {oldTransactions.map((transaction) => (
                <MDBox key={transaction._id}>
                  <Transaction
                    transactionId={transaction._id}
                    darkMode={darkMode}
                    color={
                      transaction.status === "succeeded"
                        ? "success"
                        : transaction.status === "pending"
                          ? "info"
                          : "error"
                    }
                    icon="expand_more"
                    name={transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                    description={format(new Date(transaction.createdAt), "MMM d, yyyy, h:mm a")}
                    value={`-$ ${parseFloat((transaction.amount / 100).toFixed(2))}`}
                    successStatus={transaction.status}
                    paidDate={transaction.createdAt}
                    paymentType={transaction.payment_method_details?.type || "Unknown"} // Safely access the type
                  />
                </MDBox>
              ))}
            </MDBox>
          </>
        )}
      </MDBox>
    </Card>
  );
}

export default Transactions;
