import { axiosPrivate, initializeDeviceId } from "../utils/axios";
import { refresh } from "../redux/auth/authSlice";

export const useAxiosPrivate = () => {
  axiosPrivate.interceptors.request.use((req) => {
    req.headers["device-id"] = initializeDeviceId(); // Add device ID to request headers

    if (localStorage.getItem("accessToken")) {
      const token = String(localStorage.getItem("accessToken")).split('"').join("");
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });

  axiosPrivate.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const prevRequest = error?.config;

      if (error?.response?.status === 403 && !prevRequest?.sent) {
        prevRequest.sent = true;

        const { accessToken: newAccessToken } = await refresh();
        prevRequest.headers["authorization"] = `Bearer ${newAccessToken}`;

        return axiosPrivate(prevRequest);
      }
      return Promise.reject(error);
    }
  );
  return axiosPrivate;
};
