import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MyPlan from "./components/MyPlan";
import PaymentMethod from "./components/PaymentMethod";
import DashboardLayout from "../../../LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Transactions from "./components/Transactions";
import { useMaterialUIController } from "../../../context";
import { getTransactions } from "../store/action";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ChangePaymentForm from "./components/ChangePaymentForm";
import MDBox from "../../../components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "../../../components/MDTypography"; // Import for custom typography

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function Billing() {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      await dispatch(getTransactions());
      setLoading(false); // Stop loading after data is fetched
    };

    fetchData();
  }, [dispatch]);

  return (
    <DashboardLayout>
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <CircularProgress size={60} sx={{ mb: 2 }} color="secondary" />
          <MDTypography variant="h5" sx={{ mb: 1 }}>
            Loading your transactions...
          </MDTypography>
          <MDTypography variant="body2"> Please wait while we fetch your data. </MDTypography>
        </MDBox>
      ) : (
        <>
          <Grid container spacing={2} sx={{ display: "flex", alignItems: "stretch" }}>
            <Grid item xs={12} md={6} sx={{ display: "flex" }}>
              <MyPlan />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex" }}>
              <PaymentMethod setClientSecret={setClientSecret} />
            </Grid>
          </Grid>
          <Divider />
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <Transactions darkMode={darkMode} />
          </MDBox>
          {clientSecret && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
                appearance: {
                  theme: darkMode ? "night" : "float",
                },
              }}
            >
              <ChangePaymentForm
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                darkMode={darkMode}
                brandName="LeapARMM"
              />
            </Elements>
          )}
        </>
      )}
    </DashboardLayout>
  );
}
