import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// Base URLs and API key from environment variables
const baseURL = process.env.REACT_APP_API_V1;
const ApiBaseURL = process.env.REACT_APP_API_V2;
const apiKey = process.env.REACT_APP_API_KEY;

// Initialize or retrieve the device-id
export const initializeDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4(); // Generate a unique identifier
    localStorage.setItem("deviceId", deviceId);
  }
  return deviceId;
};

// Axios instance for authenticated requests
export const axiosPrivate = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios instance for general API requests
export const api = axios.create({
  baseURL: ApiBaseURL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  },
});
