// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Kit 2 React examples

// About Us page sections
import Information from "./sections/Information";
import Team from "./sections/Team";
import Featuring from "./sections/Featuring";
import Newsletter from "./sections/Newsletter";

// Images
import bgImage from "assets/images/bg-home.jpg";
import CoverLayout from "../../../../LayoutContainers/CoverLayout";

function AboutUs() {
  return (
    <>
      <CoverLayout image={null}>
        <MDBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MDTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Work with an amazing design
              </MDTypography>
              <MDTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                We&apos;re constantly trying to express ourselves and actualize our dreams. If you
                have the opportunity to play this game
              </MDTypography>
              <MDButton sx={{ color: ({ palette: { dark } }) => dark.main }}>
                create account
              </MDButton>
              <MDTypography variant="h6" color="white" mt={8} mb={1}>
                Find us on
              </MDTypography>
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <MDTypography component="a" variant="body1" color="white" href="#" mr={3}>
                  <i className="fab fa-facebook" />
                </MDTypography>
                <MDTypography component="a" variant="body1" color="white" href="#" mr={3}>
                  <i className="fab fa-instagram" />
                </MDTypography>
                <MDTypography component="a" variant="body1" color="white" href="#" mr={3}>
                  <i className="fab fa-twitter" />
                </MDTypography>
                <MDTypography component="a" variant="body1" color="white" href="#">
                  <i className="fab fa-google-plus" />
                </MDTypography>
              </MDBox>
            </Grid>
          </Container>
        </MDBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Information />
          <Team />
          <Featuring />
          <Newsletter />
        </Card>
      </CoverLayout>
    </>
  );
}

export default AboutUs;
