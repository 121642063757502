import { Navigate, Route, Routes } from "react-router-dom";
import SubscriptionServices from "./subscriptionservices";
import Success from "./success";
import Cancel from "./cancel";
import { useMaterialUIController } from "../../../context";
import CoverLayout from "../../../LayoutContainers/CoverLayout";
// import bgImage from "assets/images/bg-home.jpg";

const PaymentPage = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <CoverLayout>
      <Routes>
        <Route path="/" element={<SubscriptionServices darkMode={darkMode} />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="*" element={<Navigate to="/payment" />} />
      </Routes>
    </CoverLayout>
  );
};

export default PaymentPage;
